.register-container {
  width: 100%;
  display: flex;
  background: $bg;
  position: relative;
  justify-content: center;

  &-success {
    align-items: center;
    height: 100vh;

    p {
      margin-top: 10px;
      line-height: 18px;
    }
  }

  &__logo {
    position: absolute;
    top: 35px;
    left: 5%;
  }

  &__img {
    width: 80%;
    max-width: none;
    @include for-size(tablet){
      width: 100%;
      max-width: 180px;
    }
  }

  &__conditions_accept {
    color: #9B9B9B;
    font-family: Sailec;
    font-weight: 100;
  }
}

.register-block {
  width: 50%;
  margin-top: 100px;


  @include for-size(phone-only) {
    width: 90%;
  }

  @include for-size(tablet) {
    min-width: 500px;
    width: 90%;
  }

  @include for-size(desktop) {
    min-width: 500px;
    width: 50%;
  }

  fieldset {
    margin-top: 10px;
  }

  .btn {
    float: right;
  }
}

.acceptConditions {
  &-cgs__error-message, &-privacy-policy__error-message {
    font-size: 14px;
    color: red;
    display: none;
  }
}

#popup-accept-privacy-policy, #popup-accept-cgs {
  .popin__close-container {
    display: none;
  }
}
