.field-wrapper__select {
  @extend input;
  cursor: pointer;
  z-index: 3;
  background: #FFF;
  display: flex;
  align-items: center;
  position: relative;

  &.valid {
    border-color: $success;
  }

  &.error {
    border-color: $danger;
  }

  &:after {
    content: ' ';
    position: absolute;
    right: 15px;
    top: 25px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $darkBlueGrey;
  }

  &-input {
    display: none;
  }

  &-selected {
    color: #000 !important;
    font-size: 15px !important;
    line-height: 25px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    width: calc(100% - 35px);
    white-space: nowrap;
    text-align: left;
  }

  &-options {
    @extend %shadow-select;
    display: none;
    max-height: 300px;
    overflow-y: auto;
    margin-left: -11px;
    margin-right: -11px;
    background-color: $white;
    border: 1px solid $lightestBlueGrey;
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
  }

  .field-wrapper.open &-options {
    display: block;
    position: absolute;
    top: 53px;
    left: 10px;
    right: 8px;
  }

  .focus & {
    border-radius: 4px;
    border: 2px solid #4FC0D9;
    box-shadow: 0px 0px 4px rgba(79, 192, 217, 0.2);
  }

  .field-wrapper__label ~ & > p {
    padding-top: 15px !important;
  }

  &-option {
    z-index: 0;
    cursor: pointer;
    display: block;
    position: relative;
    line-height: 40px;
    height: 40px;
    color: $darkBlueGrey;
    padding: 0 10px;
    transition: .25s all;
    user-select: none;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &:hover, &.hover {
      background: $lightestGrey;
    }

    &[disabled="disabled"] {
      pointer-events: none;
      background-color: $darkBlueGrey;
      color:white;
    }
  }
}

.field-wrapper__autocomplete-results {
  @extend %shadow-select;
  display: none;
  z-index: 4;
  max-height: 300px;
  overflow-y: auto;
  margin-left: -11px;
  margin-right: -11px;
  background-color: $white;
  border: 1px solid $lightestBlueGrey;
  border-bottom-left-radius: $radius;
  border-bottom-right-radius: $radius;
}

.field-wrapper.open .field-wrapper__autocomplete-results {
  display: block;
  position: absolute;
  top: 53px;
  left: 11px;
  right: 11px;
}

.field-wrapper__autocomplete-result {
  z-index: 0;
  cursor: pointer;
  display: block;
  position: relative;
  line-height: 40px;
  height: 40px;
  color: $darkBlueGrey;
  padding: 0 10px;
  transition: .25s all;
  user-select: none;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &:hover, &.hover {
    background: $lightestGrey;
  }
}