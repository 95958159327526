.depot {
  background-color: #FFF;
  display: flex;
  width: 100%;
  min-height: 80vh;
  margin-bottom: 150px;

  .depot-tab {
    display: none;
    min-width: 0;

    &.active {
      display: block !important;
    }
  }

  &__step-title {
    color: var(--primary);
    padding: 20px 0;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    max-width: 480px;
  }

  &__synthesis {
    width: 100%;
    padding: 45px 45px 200px 45px;
    background: url(../../images/bg_form.png) 50% 100% no-repeat $lightestBlue;
    border-left: 1px solid $lightestGrey;

    &__title {
      color: var(--primary);
      padding: 0 0 45px 38px;
      font-weight: bold;
      font-size: 18px;
    }

    &__table-label {
      color: $lightGrey;
      text-transform: uppercase;
      width: 90%;
      margin: auto;
      padding: 20px 0;
      letter-spacing: .5px;
      font-weight: bold;
      font-size: 13px;
    }

    .simulation__form {
      position: relative;

      .btn-cta {
        position: absolute;
        top: 0;
        right: 60px;
      }
    }

    .upload-step {
      padding-left: 40px;

      &__message {
        margin: 5px 5px;
        color: red;
        font-size: 1em;
        line-height: 1.25em;

        ul {
          list-style: initial;
          margin-left: 20px;
          padding-left: 20px;
        }
      }
    }

    .pictos {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      @include for-size(desktop) {
        width: 150%;
        max-width: 719px;
      }

      &__container {
        width: 180px;
        text-align: center;
        margin: 30px auto 90px;
        cursor: pointer;
        height: 80px;
        border: 2px solid $primary;
        border-radius: 90px / 40px;
        padding: 5px;

        &.active {
          background: $primary;
        }

        .picto {
          margin-top: -30px;
        }

        .picto-label {
          font-weight: 100;
          display: inherit;
          padding-top: 10px;
          color: $primary;
        }
      }
    }

    .depot-table {
      width: 90%;
      margin: auto;
      padding: 20px 0;

      &__header {
        color: $lightGrey;
        display: flex;
        font-size: 11px;
        font-weight: bold;
        padding: 20px 0;

        &__item:first-child {
          width: 60%;
        }

        &__item:nth-child(2) {
          width: 15%;
        }

        &__item:nth-child(3) {
          width: 15%;
        }

        &__item:last-child {
          width: 10%;
        }
      }

      &__actions {
        color: $darkestGrey;
        width: 100%;

        &__list {
          display: flex;
          font-size: 13px;
          padding: 20px 0;
          border-bottom: 1px solid $lightestGrey;

          &__item:first-child {
            width: 60%;
            padding-right: 20px;
          }

          &__item:nth-child(2) {
            width: 15%;
          }

          &__item:nth-child(3) {
            width: 15%;
          }

          &__item:last-child {
            width: 10%;
          }
        }
      }
    }

    .btn-top {
      position: absolute;
      top: 240px;
      right: 30px;
    }

    .api-button-wrapper {
      p {
        display: flex;
        flex-basis: 100%;
      }
    }
  }
}

.depot-list__footer {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 5px;
  justify-items: center;
  align-items: center;

  .pages-navigation {
    grid-column-start: 2;
    display: inline-block;
  }

  .dropdown {
    justify-self: flex-end;

    .btn-dropdown {
      padding: 5px;
      background-color: transparent;
      border-radius: 3px;
      border: 1px solid var(--cta);
      color: var(--cta);
    }

    .dropdown-content.dropdown-up {
      top: -155px;
      right: 0px;
    }
  }
}

.depot-operation-tab {
  &__header {
    .header-wrapper {
      @include for-size(tablet) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

// depot header
.depot-list__header, .depot-header {
  margin-bottom: 10px;
  z-index: 2;
  .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .date-wrapper {
      margin-top: 10px;
      text-align: right;

      p {
        display: block;
      }
    }
  }
  .header-wrapper {
    @include for-size(tablet) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .buttons-wrapper {
      @include for-size(tablet) {
        display: flex;
        align-items: center;
      }


      .btn-cta {
        margin-left: 10px;
        height: 30px;
        padding: 0 8px;
        z-index: 2;
        @include for-size(tablet) {
          padding: 0 30px;
        }

        &__icon {
          margin-right: 0;
          @include for-size(tablet) {
            margin-right: 5px;
          }
        }

        &__label {
          display: none;
          @include for-size(tablet) {
            display: block;
          }
        }
      }

      .btn-negative {
        margin-left: 5px;
      }
    }
  }

  &__buttons {
    padding-top: 30px;

    .field-wrapper.inline-block {
      min-width: auto !important;
      margin: 0;
      padding: 0 15px 0 10px;
    }

    .btn-negative {
      margin-left : 10px;
    }

    .field-wrapper__checkbox-label {
      font-size: 14px;
    }
  }

  &__buttons.synthesis_list_operations {
    margin-bottom: 20px;
    padding-top: 0;
  }
}

.bg-operation_validated-list {
  background: #d0ffbd;
}

.bg-operation_in_waiting-list {
  background: #ffbdcd;
}

.alert.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  padding: 15px;
  width: 100%;
}

.alert.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  padding: 15px;
  width: 100%;
}

.disabled_input {
  background-color: #eee;
  cursor: default;
  margin-bottom: 10px;

  &__label {
    margin-bottom:8px;
  }
}

.center-div {
  display: flex;
  align-items: center;
  justify-content: center;
}
