.dropzone .dz-preview:hover .dz-image img {
  -webkit-filter: none!important;
  filter: none!important;
}

.dropzone .dz-preview:hover .dz-error-message {
  opacity: 0!important;
}

.dz-error-mark {
  display: none!important;
}

.dz-error-message {
  opacity: 1!important;
  background: none!important;
  color: #be2626!important;
  left: 5px!important;
  top: 30px!important;
  width: 110px!important;
}

.dz-error-message:after {
  content: none!important;
}