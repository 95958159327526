
.bonus-form + .content__delete-btn {
  position: absolute;
  left: 180px;
  bottom: 56px;
}

.currency-symbol {
  height: 100%;
}

.field-wrapper.focus {
  .currency-symbol:before {
    display: block;
  }
}

.field-wrapper.fullfilled {
  .currency-symbol:before {
    display: block;
  }
}

.currency-symbol:before {
  content: 'â¬';
  position: absolute;
  display: none;
  top: 18px;
  font-size: 15px;
  @include for-size(tablet) {
    top: 23px;
    font-size: 18px;
  }
}

.currency-input {
  padding-left: 25px !important;
}