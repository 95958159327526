.row-synthesis {
  margin: auto;
  font-family: Sailec;
  font-weight: bold;
  padding-bottom: 60px;

  &__header {
    display: flex;
    border-bottom: 1px solid $lightPrimary;
    justify-content: space-between;
    align-items: baseline;


    &__title {
      color: var(--primary);
      font-size: 13px;
    }

    .icon-edit {
      padding: 10px 0px 5px 10px;
      color: $primary;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__list-header {
    display: flex;
    width: 100%;
    padding: 25px 0 10px;

    &__item {
      width: 33%;

      .item__label {
        text-transform: uppercase;
        font-size: 11px;
        color: $lightGrey;
        padding-right: 10px;
        letter-spacing: .5px;
        text-transform: uppercase;
        line-height: 14px;
        width: 80%;
      }
    }
  }

  &__list-content {
    display: flex;
    width: 100%;

    &__item {
      width: 33%;

      .item__label {
        color: $darkestGrey;
        font-size: 13px;
        line-height: 16px;
      }
    }
  }
}

.depot-header {
  position: relative;
  width: 100%;
  color: var(--primary);
  padding: 25px 0 25px 38px;
  font-family: Sailec;

  &__back-button {
    position: absolute;
    top: 30px;
    right: 0px;

    .button-label {
      font-weight: 100;
      text-transform: uppercase;
      color: $white;
      margin-top: 2px;
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    text-transform: uppercase;
    font-size: 25px;
    font-family: DIN Condensed, sans-serif;
    letter-spacing: 2px;
  }

  &__depot-reference {
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
  }

  .field-wrapper.fullfilled {
    width: 400px;
    display: block;
    z-index: 6;
  }
}

.operation-header.bannerIsSticky {
  padding: 25px 0 140px;
}

.upload-list {
  margin: 40px 0 50px;

  .list {
    margin: 5px 0;
  }

  &__header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--primary);
    padding-bottom: 3px;

    .icon {
      color: red;
      font-size: 23px;
    }

    &__label {
      font-weight: bold;
      padding: 3px 0 0 6px;
      color: var(--primary);
    }
  }

  &__table {
    padding: 10px 0 25px;

    &__item {
        display: flex;
        align-items: center;
        position: relative;
        padding: 15px 0;

        &:before {
          content: "...";
          font-size: 26px;
          color: $lightGrey;
          letter-spacing: 2px;
          position: absolute;
          display: block;
          top: 2px;
          padding-left: 15px;

        }

        &:hover {
          cursor: pointer;
        }

      &__label {
        font-size: 15px;
        padding-left: 45px;
        color: $darkestGrey;
      }
    }

    &__item:nth-child(odd) {
      background: $white;
    }
  }
}


// POPINS
.popin-operation {
  display: none;

  .popin-background {
    display: flex;

    .popin {
      display: block;
    }
  }

}

.show-popin {
  display: block !important;
}

// STICKY BANNER
.sticky {
  position: fixed;
  left: 235px;
  top: 0;
  z-index: 100;
  border-top: 0;
  width: calc(100% - 230px);
  margin: 0;
  border-radius: 0;
  border-bottom: $lightestGrey;
  box-shadow: 0 4px 10px 0px rgba(0, 0, 0, 0.05);

  .operation-header__banner__header {
    border-radius: 0;
  }

  .operation-header__banner__content {
    padding: 16px 20px
  }
}

.link-disabled {
  pointer-events: none !important;
  cursor: default;
  color: #292929;
  background-color: gray;
  border-color: #292929;
}
