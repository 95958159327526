@font-face {
  font-family: Sailec;
  font-weight: normal;
  src: url("../fonts/sailec-webfont.ttf") format("truetype"),
       url('../fonts/sailec-webfont.woff2') format('woff2'),
       url('../fonts/sailec-webfont.woff') format('woff');
}

@font-face {
  font-family: Sailec;
  font-weight: 100;
  src: url("../fonts/sailec-light-webfont.eot?#iefix") format("embedded-opentype"),
       url("../fonts/sailec-light-webfont.otf") format("opentype"),
       url("../fonts/sailec-light-webfont.woff") format("woff"),
       url("../fonts/sailec-light-webfont.woff2") format("woff2");
}

@font-face {
  font-family: Sailec;
  font-weight: bold;
  src: url("../fonts/sailec_bold-webfont.eot?#iefix") format("embedded-opentype"),
       url("../fonts/sailec_bold-webfont.otf") format("opentype"),
       url('../fonts/sailec_bold-webfont.woff2') format('woff2'),
       url('../fonts/sailec_bold-webfont.woff') format('woff');
}

@font-face {
  font-family: DIN Condensed;
  font-weight: bold;
  src: url("../fonts/dincondensed-bold-webfont.ttf") format("truetype"),
       url('../fonts/dincondensed-bold-webfont.woff2') format('woff2'),
       url('../fonts/dincondensed-bold-webfont.woff') format('woff');
}