.row-synthesis {
  margin: auto;
  font-family: Sailec;
  font-weight: bold;
  padding-bottom: 60px;

  &__header {
    display: flex;
    border-bottom: 1px solid $lightPrimary;
    justify-content: space-between;
    align-items: baseline;


    &__title {
      color: var(--primary);
      font-size: 13px;
    }

    .icon-edit {
      padding: 10px 0px 5px 10px;
      color: $primary;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__list-header {
    display: flex;
    width: 100%;
    padding: 25px 0 10px;

    &__item {
      width: 33%;

      .item__label {
        text-transform: uppercase;
        font-size: 11px;
        color: $lightGrey;
        padding-right: 10px;
        letter-spacing: .5px;
        text-transform: uppercase;
        line-height: 14px;
        width: 80%;
      }
    }
  }

  &__list-content {
    display: flex;
    width: 100%;

    &__item {
      width: 33%;

      .item__label {
        color: $darkestGrey;
        font-size: 13px;
        line-height: 16px;
      }
    }
  }
}

.sidebar-operation {

  &__list {
    padding: 20px;

    &__item {
      display: flex;
      align-items: center;
      color: $lightGrey;

      &.active {
        color: var(--primary);
      }

      &__label {
        font-weight: bold;
        font-size: 13px;
        line-height: 15px;
        padding: 5px 0 5px 8px;

        &:hover {
          cursor: pointer;
        }
      }

      &:before {
        content:"Â·";
        font-size: 60px;
        text-align: center;
        margin-top: -8px;
      }
    }
  }
}

.operation-header {
  position: relative;
  width: 100%;
  color: var(--primary);
  padding: 25px 0;
  font-family: Sailec;

  .field-wrapper__checkbox-label {
    font-size: 12px;
    color: $darkestGrey;
  }

  .field-wrapper.sub-wrapper {
    background: $white;
    cursor: pointer;
    width: 35%;

      .field-wrapper__label {
        padding-top: 3px;
      }

      .field-wrapper__select {
        border: 1px solid $primary;
      }

      .field-wrapper__select.focus {
        border-radius: 4px;
        border: 2px solid $cta;
        box-shadow: 0px 0px 4px rgba(79, 192, 217, 0.2);
      }
  }

  .field-wrapper.fullfilled {
    width: 400px;
    display: block;
    z-index: 6;
  }

  form[name='state'] {
    position: relative;
    z-index: 5;
    display: inline-block;
  }

  &__back-button {
    position: absolute;
    top: 30px;
    right: 0px;

    .button-label {
      font-weight: 100;
      text-transform: uppercase;
      color: $white;
      margin-top: 2px;
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    text-transform: uppercase;
    font-size: 25px;
    font-family: DIN Condensed, sans-serif;
    letter-spacing: 2px;
  }

  &__operation-name {
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
  }

  &__operation-number {
    color: $lightGrey;
    font-size: 14px;
    line-height: 20px;
  }


  &__operation-status {
    display: inline-block;
    border-radius: 20px;
    padding: 10px 20px 6px;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 1px;
    font-weight: 100;
    margin: 8px 0;
    color: $white;
  }

  &__download-link {
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 15px;
    font-weight: normal;
    display: block;
    padding: 8px 0;

    &:hover {
      cursor: pointer;
    }
  }


  &__banner {
    margin: 20px 0;
    background: $white;
    border-radius: $radius;
    top: 0;
    position: -webkit-sticky;
    position: sticky;

    &__header {
      display: flex;
      color: $white;
      padding: 8px;
      border-radius: $radius;
      align-items: center;
      letter-spacing: 1.5px;

      .header-label {
        padding-left: 10px;
        text-transform: uppercase;
        font-size: 10px;
      }

      .icon-warning::before {
          color: $white
      }
    }

    &__line {
      height: 5px;
      position: absolute;
      width: 100%;
      margin-top: -5px;
    }

    &__content {
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .content-text {
        color: $darkestGrey;
        font-size: 12px;
        line-height: 15px;
      }

      .content-button {
        display: flex;
        font-weight: 100;
        font-size: 10px;
        letter-spacing: 1.5px;
        padding: 8px 15px;
        align-items: center;

        p {
          height: auto;
          text-transform: uppercase;
          padding-left: 8px;
        }
      }
    }
  }

  &__cancel-container {
    margin: 45px 5px 5px 0px;
    float: right;
  }

  &__cancel-link {
    text-align: right;
    display: inherit;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: normal;
    letter-spacing: 1px;
    color: var(--primary);
    background-color: transparent;
    border: none;
    outline: transparent;
  }

}

.operation-header.bannerIsSticky {
  padding: 25px 0 140px;
}

.upload-list {
  margin: 40px 0 50px;

  .list {
    margin: 5px 0;
  }

  &__header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--primary);
    padding-bottom: 3px;

    .icon {
      color: red;
      font-size: 23px;
    }

    &__label {
      font-weight: bold;
      padding: 3px 0 0 6px;
      color: var(--primary);
    }
  }

  &__table {
    padding: 10px 0 25px;

    &__item {
        display: flex;
        align-items: center;
        position: relative;
        padding: 15px 0;

        &:before {
          content: "...";
          font-size: 26px;
          color: $lightGrey;
          letter-spacing: 2px;
          position: absolute;
          display: block;
          top: 2px;
          padding-left: 15px;

        }

        &:hover {
          cursor: pointer;
        }

      &__label {
        font-size: 15px;
        padding-left: 45px;
        color: $darkestGrey;
      }
    }

    &__item:nth-child(odd) {
      background: $white;
    }
  }
}


// POPINS
.popin-operation {
  display: none;

  .popin-background {
    display: flex;

    .popin {
      display: block;
    }
  }

}

.show-popin {
  display: block !important;
}

// STICKY BANNER
.sticky {
  position: fixed;
  left: 235px;
  top: 0;
  z-index: 100;
  border-top: 0;
  width: calc(100% - 230px);
  margin: 0;
  border-radius: 0;
  border-bottom: $lightestGrey;
  box-shadow: 0 4px 10px 0px rgba(0, 0, 0, 0.05);

  .operation-header__banner__header {
    border-radius: 0;
  }

  .operation-header__banner__content {
    padding: 16px 20px
  }
}