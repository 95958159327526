$inputHeightMobile: 45px;
$inputHeight: 55px;


input {
  padding: 0 10px;
  width: 100%;
  height: $inputHeightMobile;
  background-color: $transparent;
  border: 1px solid $lightestBlueGrey;
  border-radius: $radius;
  font-size: 15px;
  color: $darkBlueGrey;
  @include for-size(tablet) {
    height: $inputHeight;
  }

  &[type=file] {
    padding: 0;
    height: auto;
    border: none;
    display: none;
  }

  &::placeholder {
    color: $lightGrey;
    font-family: Sailec;
    font-weight: 100;
    font-size: 15px;
    line-height: $inputHeight;
  }
}

.number-wrapper {
  .field-wrapper__field {
    padding-left: 10px;
  }
}

.field-wrapper {
  outline: none;
  display: inline-flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  margin: 8px 0;
  @include  for-size(tablet) {
    min-width: 460px;
  }

  &__container {
    margin-top: 0;
    outline: none;
    @include for-size(tablet) {
      display: inline-flex;
    }
  }

  &--col {
    display: flex;
    flex-direction: column;
  }

  &--row {
    display: flex;
    flex-direction: row;
  }

  .icon {
    position: absolute;
    left: 10px;
    top: 15px;
    font-size: 22px;
    color: $lightGrey;

    &.icon-close {
      position: absolute;
      right: 10px;
      left: unset;
      font-size: 14px;
      top: 20px;
    }

    &.icon-calendar {
      z-index: 1;
    }
  }

  .icon + input {
    padding-left: 40px;
  }

  &.open {
    z-index: 99 !important;
  }

  &.valid {
    &:after {
      position: absolute;
      display: block;
      right: -30px;
      top: 20px;
      content: "\e90c";
      color: $success;
      font-family: icomoon;
    }
  }

  &__label {
    @extend input::placeholder;
    z-index: 4;
    position: absolute;
    padding: 0 10px;
    transition: .25s all;
    user-select: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    display: block;
    text-align: left;
    cursor: text;

    &--classic {
      position: relative;
      line-height: 22px;
      font-weight: 400;
      font-size: 14px;
      padding: 10px 0;
      color: var(--primary);
    }

    .errors-container {
      white-space: initial;
    }

    &.changed {
      color: $darkBlueGrey;
    }

    &-datepicker {
      left: 30px;
      font-size: 15px;
      white-space: nowrap;
      width: calc(100% - 22px);

      .errors-container {
        white-space: initial;
      }
    }

    &__file {
      position: relative;
      border: 1px solid $lightestBlueGrey;
      border-radius: 3px;
      padding-right: 40px;
      height: $inputHeightMobile;
      background: $white;
      @include for-size(tablet) {
        height: $inputHeight;
      }
      &:after {
        position: absolute;
        display: block;
        right: 15px;
        top: 0;
        content: "\e920";
        color: var(--primary);
        font-family: icomoon;
      }
    }

    .focus &:not(.field-wrapper__label__file), .fullfilled &:not(.field-wrapper__label__file) {
      padding-top: 2.5px;
      font-size: 10px;
      line-height: 30px;
    }

    &.select2__label {
      position: relative;
      padding: 0;
      line-height: 25px;
    }
  }

  .choice-label {
    bottom: -15px;
  }

  .select-label {
    z-index: 99;
  }

  &__field {
    z-index: 1;
    display: flex;
    padding-top: 15px;
    width: 100%;
    background: $white;

    &:focus {
      outline: 1px solid $cta;
      border-color: $cta;
      box-shadow: 0px 0px 4px rgba($cta, .2);
    }

    &.datepicker {
      padding: 15px 0 0 40px;
    }

    // Input states.
    &.error {
      border-color: $danger;
    }

    &.valid {
      border-color: $success;
    }
  }

  &__preview {
    text-align: left;
    margin-top: 10px;

    img {
      max-width: 200px;
    }
  }

  .selection {
    padding-top: 9px !important;
    color: $black !important;
    font-size: 15px !important;
    line-height: 25px !important;
  }

  // Label states.
  &__field.not-empty:invalid + &__label,
  &__field.not-empty.error + &__label {
    color: $danger;
  }

  &__info{
    position: absolute;
    right: 15px;
    bottom: 12px;
    z-index: 15;
    overflow: hidden;
    height: 25px;

    &:hover{
      overflow: visible;
      z-index: 40;

      & > .icon-info{
        color: var(--primary);
      }
      *{
        opacity: 1;
      }
    }

    & > .icon-info{
      display: block;
      font-size: 25px;
      color: $lightGrey;
      transition: all .3s ease;
      cursor: pointer;
      &:hover{
        color: var(--primary)
      }
    }

    &__wrapper{
      position: absolute;
      right: -20px;
      top: 35px;
      background: $white;
      padding: 20px;
      border-radius: 3px;
      width: 400px;
      opacity: 0;
      transition: all .3s ease;
      box-shadow: 0px 0px 4px rgba($black, .3);

      &:before,
      &:after {
        position: absolute;
        display: block;
        pointer-events: none;
        border: solid transparent;
        border-bottom-color: $white;
        content: '';
        height: 0;
        width: 0;
        top: -5px;
        right: 27px;
      }

      &:before {
        border-width: 5px;
        margin: -5px 0;
      }
      &:after {
        border-width: 4px;
        margin: -4px 0;
      }
    }

    &__content{
      font-size: 16px;
      font-family: Sailec;
      cursor: initial;

      &__title{
        padding-bottom: 5px;
        i, p{
          display: inline;
          font-weight: bold;
          padding-right: 5px;
        }
        p{ font-size: 14px; }
      }
      &__desc{
        color: $lightGrey;
        font-size: 15px;
        line-height: 27px;
      }
    }
  }

}

.field-wrapper.error {
  .field-wrapper__label__file {
    display: block !important;
  }
}

.field-wrapper.checkbox-wrapper {
  &> .field-wrapper__label {
    line-height: 22px;
    font-weight: 400;
    font-size: 14px;
    text-overflow: initial;
    white-space: initial;
    color: var(--primary);
  }
}

.field-wrapper.no-background {

  .operationCustomLabel {
    display: block;
    white-space: normal;
  }
  .field-wrapper__label {
    position: relative;
    font-size: 12px;
  }
}

.form-validator{
  right: 20px;
  bottom: 20px;
  z-index: 3;
  position: absolute;
  width: 250px;

  &__button{
    background: var(--primary);
    color: $white;
    font-family: Sailec;
    font-size: 10px;
    padding: 10px;
    cursor: pointer;
    text-align: center;
    border: none;
    float: right;
  }

  &__button--save {
    &:before {
      display: inline;
      font-family: 'icomoon' !important;
      content: "\e924";
      margin-right: 7px;
      line-height: 0px;
      vertical-align: middle;
      font-size: 15px;
    }
  }

  &__button--next {
    &:before {
      display: inline;
      font-family: 'icomoon' !important;
      content: "\e911";
      margin-right: 7px;
      line-height: 0px;
      vertical-align: middle;
      font-size: 15px;
    }
  }

  &__button:first-child {
    margin-bottom: 20px;
  }
}

// Specific case for vich-file
#operation_precarity_evidence {
  .field-wrapper {
    width: 100%;

    .vich-file {
      .field-wrapper {
        width: 100%;
      }
    }
  }
}

//specific case for duplicate operation popin

#form_duplicateOperation_duplicateOptions {
  display:flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .field-wrapper__checkbox-label {
    line-height: 35px;
    width: 50%;
  }
}

.checkbox-container {
  display:flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .field-wrapper {
    width: 50%;
    min-width: auto;
  }
}

.popin__states textarea {
  height: 100px;
}
