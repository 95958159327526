.user-profile {
  @extend %shadow-content;
  display: flex;
  overflow: hidden;
  padding: 0 20px;
  width: 100%;
  position: relative;
  background: $white;
  @include for-size(tablet) {
    padding: 0;
    height: 85vh;
    background: none;
  }

  .force-change-password {
    text-align: left;
    margin-bottom: 15px;
  }
}
