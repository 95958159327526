.field-wrapper__radio {
  display: none;

  &-label {
    display: flex;
    cursor: pointer;
    position: relative;
    align-items: center;
    font-size: 13px !important;
    white-space: inherit;

    &:before {
      content: " ";
      margin: 0 10px 0 1px;
      min-width: 20px;
      height: 20px;
      border: 1px solid #9B9B9B;
      border-radius: 50%;
      color: #FFF;
    }

    &.focus:before {
      border: 1px solid $cta;
    }
  }

  &:checked + &-label:before {
    border: 4px solid $white;
    background-color: var(--primary);
    box-shadow: 0 0 0 1px var(--primary);
    height: 15px;
    min-width: 15px;
    top: 5px;
  }
}