.cumulativeSimulation__wrapper {
  display: flex; // Because Guillaume Lacourt love it
  flex-direction: column;
  transition: 0.4s ease all;
  background-color: $primary;
  border-radius: 5px;
  padding: 15px 15px;

  .cumulativeSimulation__summary {
    &__title {
      font-size: 1rem;
      color: white;
      text-transform: uppercase;

      .cumulativeSimulation__total {
        font-weight: bold;
        display: inline-block;
        margin-top: 20px;
        font-size: 1.6rem;

        &:after {
          content: ' â¬'
        }
      }
    }

    button.show-details {
      margin-top: 5px;
      text-transform: uppercase;
      text-decoration: underline;
      color: white;
      font-size: 0.6rem;
      border: none;
      background-color: initial;
      padding: 0 0;
      font-family: "Sailec", sans-serif;
    }
  }

  .cumulativeSimulation__details {
    margin-top: 10px;
    color:white;
    width: 100%;
    display:none;

    &__item {
      display: flex;
      line-height: 24px;

      div:nth-child(1) {
        min-width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      div:nth-child(2) {
        min-width: calc(20% - 20px);
        max-width: calc(60% - 20px);
        width: calc(60% - 20px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0 10px;
        text-rendering: optimizeLegibility !important;
        -webkit-font-smoothing: antialiased !important;
      }

      div:nth-child(3) {
        white-space: nowrap;

        &:after {
          content: ' â¬'
        }
      }
    }
  }
}