.simulation-list {
  padding: 20px;

  &__header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-bottom: 15px;
    z-index: 2;
    @include for-size(tablet) {
      flex-direction: row;
    }

    .btn-cta {
      margin : 20px auto 0;
      padding: 0 15px;
      @include for-size(tablet) {
        margin: 0;
        padding: 0 30px;
      }
    }
  }

  .table-head__item {
    font-weight: bold;
    line-height: 11px;
    vertical-align: top;
    height: auto;
    padding: 10px 30px 10px 15px;
  }

  .table-body__detail > .table-body__detail__link-edit:first-child {
    margin-right: 15px;
  }

  .--bold {
    font-weight: bold;
  }
}

.simulation.content {
  position: relative;
  padding: 0;
  margin: 0;
  height: 75vh;
  @include for-size(tablet) {
    height: 80vh;
    width: 100%;
  }
}

.simulation {
  display: flex;
  flex-direction: column;
  @include for-size(tablet) {
    flex-direction: row;
  }

  &__sidebar {
    overflow-y: scroll;
  }


  &__form {
    @include for-size(tablet) {
      overflow-y: scroll;
      padding-left: 40px;
      width: 100%;
    }

    .blue-label {
      position: initial;
      padding: 0;
      left: 0;
      color: $primary;
    }

    .step-content__details {
      padding: 0 !important; //overwrite middleStep padding style
      margin-top: -15px;
    }

    .icon-calendar {
      z-index: 2;
    }

    .fieldset {
      margin-bottom: 0;
      @include for-size(tablet) {
        height: 100%;
        background: url(../../images/bg_form.png) 50% 100% no-repeat $lightestBlue;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 240px;
        right: 0;
        overflow-y: scroll;
      }

      .form-validator {
        right: 20px;
        bottom: 20px;
        width: 150px;

        &__button {
          position: initial;
          display: flex;
          align-items: center;
        }
      }
    }

    &__mainStep.done{
      .form-validator{
        display: none;
      }
    }

    &__lastStep {
      display: flex;
      align-items: center;
      min-height: 75vh;
      padding: 0 20px;
      font-family: Sailec;
      color: var(--primary);
      @include for-size(tablet) {
        min-height: 80vh;
        width: 100% !important;
        min-width: 450px;
      }
    }

    &__middleStep {
      min-height: 75vh;
      padding: 0 20px;
      font-family: Sailec;
      color: var(--primary);
      @include for-size(tablet) {
        min-height: 80vh;
        width: 60%;
        min-width: 450px;
      }

      .hidden-simulation-input {
        display: none;
      }

      .pictos {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        @include for-size(desktop) {
          width: 150%;
          max-width: 719px;
        }

        &__container {
          width: 180px;
          text-align: center;
          margin: 30px auto 90px;
          cursor: pointer;
          height: 80px;
          border: 2px solid $primary;
          border-radius: 90px / 40px;
          padding: 5px;

          &.active {
            background: $primary;
          }

          .picto {
            margin-top: -30px;
          }

          .picto-label {
            font-weight: 100;
            display: inherit;
            padding-top: 10px;
            color: $primary;
          }
        }
      }

      .step-content {
        line-height: 21px;
        @include for-size(tablet) {
          padding-left: 36px;
        }

        &__label {
          font-size: 15px;
          display: block;

          &[for="worksForm"] {
            padding-bottom: 20px;

            & + .field-wrapper {
              .field-wrapper__select {
                height: 70px;
                @include for-size(tablet) {
                  height: 55px;
                }
              }

              .field-wrapper__label {
                line-height: 19px;
                padding-top: 10px;
                @include for-size(tablet) {
                  padding-top: 20px;
                }
              }
            }
          }
        }

        &__details {
          font-size: 12px;
          display: block;
          padding: 10px 0;
        }

        &__input {
          background: $white;
          margin-bottom: 40px;
          width: 70%;
        }

        &__input:focus {
          border: 1px solid $cta;
        }

      }

      .field-wrapper__datepicker + .errors-container {
        margin-bottom: 15px;
        margin-top: -20px;
      }

      .field-wrapper__field, .field-wrapper__select, .checkbox-wrapper {
        background: $white;
        margin-bottom: 20px;

        & + .errors-container {
          margin-bottom: 10px;
          margin-top: -20px;
        }
      }

      .field-wrapper__radio-label {
        font-size: 14px !important;
        line-height: 26px;
        color: $black;
        padding-left: 36px;
      }
    }

    &__header-buttons {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
    }
  }

  .btn-cta {
    width: 150px;
    margin: 0 auto 20px;
    justify-content: center;
    display: flex;
  }
}

.simulation {
  .flatpickr-calendar {
    margin-bottom: 50px;
  }
}

.valid-tax-info {
  cursor: pointer;
  text-decoration: underline;
  position: relative;
  top: -25px;
  font-size: 14px;
}

.operation__step-content .valid-tax-info {
  top: initial;
}

.floating__save {
  display: inline-flex;
  align-items: center;
  padding: 0 10px;
  color: white;
  background-color: var(--primary);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height:40px;
  white-space: nowrap;
  cursor: pointer;
  text-transform: uppercase;
  position: fixed;
  right: 29px;
  width: 42px;
  overflow: hidden;
  top: 200px;
  z-index: 1;
  transition: .3s ease;
  border: none;

  &:hover {
    width: 130px;
  }


  .icon-save {
    margin-right: 10px;
    font-size:2em;
  }
}
