@import 'utils/reset';
@import 'utils/variables';
@import 'utils/breakpoints';
@import 'utils/global';
@import 'utils/fonts';
@import 'utils/icons';
@import 'utils/animations';

@import 'pages/admin/admin';
@import 'pages/admin/sheet';
@import 'pages/bonus_form';
@import 'pages/company_form';
@import 'pages/whiteMark_form';
@import 'pages/dashboard';
@import 'pages/login_content';
@import 'pages/legalNotices';
@import 'pages/bulk';
@import 'pages/simulation';
@import 'pages/myAccount';
@import 'pages/user_form';
@import 'pages/operations';
@import 'pages/depot';
@import 'pages/generated_form';
@import 'pages/_register';
@import 'pages/prime_form';

@import 'form/global';
@import 'form/fieldset';
@import 'form/input';
@import 'form/file';
@import 'form/error';
@import 'form/select2';
@import 'form/dz';

@import 'plugins/flatpickr';
@import 'plugins/_select2-modify';
@import 'plugins/_blockly';

@import 'extends/background';
@import 'extends/effects';

@import '../../templates/components/advisor/advisor';
@import '../../templates/components/alert/alert';
@import '../../templates/components/button/button';
@import '../../templates/components/headers/headers';
@import '../../templates/components/link/link';
@import '../../templates/components/login/login';
@import '../../templates/components/menu/menu';
@import '../../templates/components/notification/notification';
@import '../../templates/components/searchbar/searchbar';
@import '../../templates/components/submenu/submenu';
@import '../../templates/components/popin/popin';
@import '../../templates/components/form/checkbox/checkbox';
@import '../../templates/components/form/textarea/textarea';
@import '../../templates/components/form/radio/radio';
@import '../../templates/components/search/search';
@import '../../templates/components/form/select/select';
@import '../../templates/components/form/steps/steps';
@import '../../templates/components/form/stepTitle/stepTitle';
@import '../../templates/components/form/actionBar/actionBar';
@import '../../templates/components/table/table';
@import '../../templates/components/tooltip/tooltip';
@import '../../templates/components/logo/logo';
@import '../../templates/components/subtabs/subtabs';
@import '../../templates/components/pagination/pagination';
@import '../../templates/components/summarySimulation/summarySimulation';
@import '../../templates/components/operations/operations';
@import '../../templates/components/depots/depots';
@import '../../templates/components/searchfilters/searchFilters';
@import '../../templates/components/simulation/popinCalculate';
@import '../../templates/components/simulation/cumulativeSimulation';
@import '../../templates/components/comment/comment';
@import '../../node_modules/select2/dist/css/select2.min.css';
@import '../../node_modules/dropzone/dist/dropzone.css';
