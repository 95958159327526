.link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;

  &:link,
  &:visited,
  &:active {
    color: var(--primary);
  }

  &__icon {
    font-size: 16px;
  }

  &__label + &__icon,
  &__icon + &__label {
    margin-left: 10px;
  }

  &-underline {
    @extend .link;
  }

  &-underline &__label {
    text-decoration: underline;
  }
}