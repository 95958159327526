.table {
  width: 100%;
  background-color: $white;
  overflow-y: scroll;
  @include for-size(tablet) {
    overflow-y: initial;
    overflow-x: scroll;
  }
  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    padding: 10px 0;
    @include for-size(tablet) {
      flex-direction: row;
    }

    .btn-negative {
      margin-top: 10px;
      @include for-size(tablet) {
        margin: 0 0 0 10px;
      }
    }
  }

  &__container {
    width: 100%;
  }

  &-head {
    font-size: 9px;
    font-family: Sailec;
    color: $lightGrey;

    &__item,
    &__item-action {
      padding: 0 15px;
      height: 50px;
      border-bottom: 1px solid $lightestGrey;
      vertical-align: middle;
      text-align: left;
      white-space: nowrap;
    }

    &__item.sortable {
      a {
        color: $lightGrey;
        cursor: pointer;
      }

      .icon {
        margin-left:5px;
        font-size: 10px;
      }
    }
  }

  &-body {
    font-family: Sailec;

    &__item {
      height: 50px;
      border-bottom: 1px solid $lightestGrey;

      .icon {
        color: var(--primary);
      }

      &-done {
        background-color: rgba($success, .1);
      }

      &-alert {
        background-color: rgba($danger, .1);
      }

      &-done .icon-done {
        font-size: 15px;
        color: $success;
      }

      &-wait .icon-wait {
        font-size: 18px;
        color: $warning;
      }

      &-alert .icon-alert {
        font-size: 15px;
        color: $danger;
      }

      &-neutral {
        font-size: 15px;
        background: transparent;
      }
    }

    &__detail,
    &__detail-action {
      padding: 0 15px;
      font-size: 12px;
      color: $darkGrey;
      vertical-align: middle;
      white-space: nowrap;
      vertical-align: middle;
    }

    &__not-found {
      height: 55px;
      color: $lightGrey;
      text-align: center;
      vertical-align: middle;
    }

    &__detail {
      .icon-edit, .icon-trash, .icon-duplicate {
        font-size: 1.25em;
      }

      &__link-edit {
        color: $black;
      }
    }
  }

  & .slim-cell {
    width: 15px;
  }

  & .action-cell {
    padding: 0 0;

    a:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.shorted-txt {
  @include for-size(tablet) {
    text-overflow: ellipsis;
    max-width: 110px;
    overflow: hidden;
  }
}

.list {
  max-height: 200px;
  margin: 20px 0;
  @include for-size(tablet) {
    overflow-y: scroll;
    max-height: 490px;
  }
  &__item {
    display: flex;
    align-items: center;
    padding : 6px;
    @include for-size(tablet) {
      padding: 14px;
    }

    &:hover {
      cursor: pointer;
      @extend %shadow-menu;
      .trash-link {
        @include for-size(tablet) {
          display: block;
          color: $lightestGrey;
        }
      }
      .download-link {
        margin: 0;
      }
    }

    .icon {
      color: $primary;
      font-size: 22px;
    }
    .icon-download {
      font-size: 18px;
      @include for-size(tablet) {
        font-size: 22px;;
      }
    }
    &__label {
      font-weight: 100;
      font-size: 12px;
      padding-left: 10px;
      padding-top: 2px;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 70%;
      overflow: hidden;
      text-align: left;
      @include for-size(tablet) {
        padding-left: 15px;
        font-size: 13px;
        width: 90%;
      }
    }
    .trash-link {
      display: none;
      @include for-size(tablet) {
        margin: 0 40px 0 auto;
      }
    }
    .download-link {
      margin: 0 0 0 auto;
    }
  }
  &__item:nth-child(odd) {
    background: $lightestGrey;
    @include for-size(tablet) {
      background: $white;
    }
  }

  &__item--nodelete {
    &:hover {
      .download-link {
        margin: 0 0 0 auto;
      }
    }
  }
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 10px;
}

.linkedRow {
  cursor: pointer;
}

.table.scroll {
  max-height: 565px;

  table {
    border-collapse: separate; /* Don't collapse */
    border-spacing: 0;
  }

  .table-body tr td {
    border-bottom: 1px solid $lightestGrey;
  }

  .table-head tr td {
    position: sticky;
    top: 0;
    border-bottom: 1px solid $lightestGrey;
    background-color: white;
    z-index: 1;
  }
 }