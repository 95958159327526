.admin {
  &-logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $lightestGrey;
    margin: 10px;
    padding: 5px;
    width: 150px;
    height: 150px;

    &__image {
      max-width: 100%;
      height: auto;
    }
  }
}