.legal-notices {
  &__title {
    padding: 10px;
  }

  &__subtitle {
    @extend .title-h3;
    padding: 10px 20px;
    font-size: 15px;
  }

  .legal-notices__span {
    margin-right: 15px;
  }

  &__sub-subtitle {
    @extend .title-h4;
    font-weight: normal !important;
    padding: 10px 30px 0;
  }

  &__content {
    padding: 10px;
    line-height: 20px;
    font-size: 12px;
  }

  &__list {

    &-item {
      @extend .legal-notices__content;
      padding: 2px 20px;
    }
  }

  .--before {
    margin-left: 15px;
  }
}