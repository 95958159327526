.simulation__form{
    .popin-section{
        border: none;
    }
}

.popin-calculate {
  &__content {
    
    &-volume,
    &-calcul,
    &-result
    {
        margin-bottom: 30px;

        &__title{
            font-family: Sailec, sans-serif;
            font-weight: bold;
            color: var(--primary);
            font-size: 15px; 
            border-bottom: 1px solid var(--primary);
            padding-bottom: 5px;
            margin-bottom: 15px;
        }
    }

    &-volume,
    &-result{
        &__value,
        &__amount{
           font-size: 14px; 
           font-weight: bold;
        }
    }
    
    &-calcul{
        &__subtitle{
            margin-bottom: 5px;
            color: $lightGrey;
            font-size: 12px;
        }

    }
  }
  .btn-cta{
      margin: 0;
      width: auto;
  }
}