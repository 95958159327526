body {
  font-family: Sailec;
  background-color: $bg;
}

hr {
  width: 100%;
  border-top: 1px solid $lightestGrey;
}

.icon-trash {
  cursor: pointer;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  background-color: $white;
  margin-bottom: 75px;
  @extend %shadow-content;

  &__section:not(:last-child) {
    margin-bottom: 50px;
  }

  &__sidebar + &__section {
    background-color: $lightestGrey;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__back-btn {
    width: fit-content;
    padding: 30px 0 10px;
    margin: 0 auto;
  }
}

// White mark classes.
.txt-primary {
  color: var(--primary);
}

.bg-primary {
  background-color: var(--primary)
}

.txt-secondary {
  color: var(--secondary);
}

.bg-secondary {
  background-color: var(--secondary);
}

.bg-success {
  background: $success;
}

.bg-warning {
  background: $warning;
}

.bg-danger {
  background: $danger;
}

.bg-success-list {
  background: rgba($success, .2);
}

.bg-warning-list {
  background: $white;
}

.bg-danger-list {
  background: rgba($danger, .2);
}

.main-container {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  @include for-size(tablet) {
    flex-direction: row;
  }
  .container-fluid {
    width: 100%;
    @include for-size(tablet) {
      padding-right: 30px;
      margin-left: 235px;
      width: calc(100% - 230px)
    }
    &__header {
      justify-content: space-between;
      align-items: center;
      height: 50px;
      background: $white;
      border-top: 3px solid var(--primary);
      @include for-size(tablet) {
        border-top: none;
        background: none;
        height: auto;
        padding: 20px 0px 20px 20px;
        justify-content: space-between;
      }
    }

    &__content {
      min-height: 80vh;
      position: relative;
      margin: 25px 15px;
      border-radius: $radius;
      @include for-size(tablet) {
        background-color: $white;
        background-color: $bg;
        margin: 0 0 20px 20px;
      }
    }
  }
}

.no-background {
  background: none !important;
}

.xs-only {
  display: flex;
  @include for-size(tablet) {
    display: none;
  }
}

.lg-only {
  display: none;
  @include for-size(tablet) {
    display: block;
  }
}

.lg-only-flex {
  display: none;
  @include for-size(tablet) {
    display: flex;
  }
}

.hidden {
  display: none !important;
}

.unvisible {
  visibility: hidden !important;
}

.show {
  display: block !important;
}

.no-margin {
  margin: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.full-width {
  width: 100%!important;
}

.overlay {
  position: absolute;
  background: $black;
  opacity: .7;
  top: 52px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
}

.civility-label + .sub-wrapper {
    .cursor-pointer {
      top: 10px;
    }
}

.inline-block {
  display: inline-block !important;
  width: auto !important;
}

.inline {
  display: inline !important;
}

.bold {
  font-weight: bold;
}

// file uploader

.dz-success-mark svg g path {
  fill: $success !important;
}

.dz-remove {
  color: $primary;
  padding-top: 10px;
}

.dropzone {
  border-radius: 3px;
  border: 1.5px dashed $lightGrey;
  background: rgba(236, 236, 236, 0.3);
}

.dropzone.dz-started .dz-message {
  display: block;
}

.dropzone .dz-preview.dz-image-preview {
  background: none;
}

.disable-scroll {
  height: 100vh;
  overflow: hidden;
}

.bullet-list {
  list-style: initial;

  li {
    margin-top: 10px;
  }
}