.header-logo {
  width: 50px;
  height: 50px;
  background: $primary;
  display: flex;
  justify-content: center;
  align-items: center;
  @include for-size(tablet) {
    display: none;
  }
  &__img {
    width: 72%;
  }
}