.menu {
  @extend %shadow-menu;
  @include for-size(tablet) {
    display: flex;
    position: relative;
    width: 235px;
    height: 100vh;
    flex-direction: column;
    background-color: $white;
  }
  @include for-size(tablet) {
    position: fixed;
    &:before {
      display: flex;
      content: ' ';
      height: 5px;
      background-color: var(--secondary);
    }
  }

  &-logo {
    display: none;
    @include for-size(tablet) {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 30px;
      height: 82px;
      background-color: var(--primary);
    }

    &__link {
      width: 100%;
      height: 100%;

      .logo {
        display: inline-flex;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &-user {
    display: none;
    @include for-size(tablet) {
      display: block;
      padding: 15px 25px;
    }

    &__civility ,
    &__name {
      display: flex;
      font-family: Sailec;
      font-size: 12px;
      color: var(--primary);
    }

    &__name {
      padding-top: 2px;
      font-size: 20px;
    }

  }

  &-logout {
    display: none;
    @include for-size(tablet) {
      display: flex;
      position: absolute;
      align-items: center;
      padding: 10px 0 10px 30px;
      bottom: 0;
      width: 100%;
      border-top: 1px solid var(--primary);
    }

    &__icon {
      transform: rotate(-90deg);
      color: var(--primary);
      font-size: 17px;
    }

    &__label {
      margin-left: 10px;
      color: $grey;
      font-family: Sailec;
      text-transform: uppercase;
      font-size: 10px;
    }
  }

}

.burger-menu {
  z-index: 10;
  background: #FFF;
  position: absolute;
  top: 52px;
  right: 0;
  width: calc(100vw - 50px);
  height: calc(100vh - 50px);
  transform: translateX(100%);
  transition: all .3s;
  @include for-size(tablet) {
    display: none;
  }
  &__list {
    display: flex;
    flex-direction: column;
    .close {
      margin: 0 0 0 auto;
      padding: 15px 20px ;
      text-align: right;
      .icon {
        color: var(--primary);
      }
    }
    &__item {
      padding: 20px 40px 10px 20px;
      cursor: pointer;
      &__link {
        display: flex;
        align-items: center;
        color: var(--primary);
        .link-icon {
          font-size: 19px;
        }
        .link-label {
          line-height: 15px;
          font-size: 14px;
          padding-left: 15px;
        }
      }
    }

    .logout-mobile {
      padding: 20px 40px 10px 20px;
    }
  }
}

.show {
  transform: translateX(0%);
}

.menu-list {
  display: flex;
  justify-content: space-around;
  @include for-size(tablet) {
    display: block;
    margin: 57px 0 50px;
    overflow-y: scroll;
  }

  .burger-item {
    display: none;
    @include for-size(tablet) {
      display: flex;
    }
  }

  .burger {
    font-size: 25px;
    color: var(--primary);
    display: flex;
    align-items: center;
    @include for-size(tablet) {
      display: none;
    }
  }

  &__item {
    position: relative;
    height: 50px;
    @include for-size(tablet) {
      border-bottom: 1px solid $lightestGrey;
    }


    &.active {
      border-bottom: 4px solid var(--primary);
      @include for-size(tablet) {
        border-bottom: none;
        background-color: $lightestGrey;
      }
    }

    &:hover {
      border-bottom: 4px solid var(--primary);
      @include for-size(tablet) {
        border-bottom: 1px solid $lightestGrey;
      }
    }

    @include for-size(tablet) {
      &.active:before,
      &:hover:before {
        content: ' ';
        position: absolute;
        background-color: var(--primary);
        top: 0;
        left: 0;
        width: 5px;
        height: 50px;
      }
    }

    @include for-size(tablet) {
      &:after {
        font-family: icomoon;
        color: $lightGrey;
        margin-right: 15px;
        position: absolute;
        top: 35%;
        right: 0;
      }
    }

    &__link {
      display: flex;
      padding: 0 15px;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      height: 50px;
    }

    &__icon {
      color: var(--primary);
      font-size: 25px;
      @include for-size(tablet){
        margin-right: 8px;
        font-size: 18px;
      }
    }

    &__label {
      display: none;
      @include for-size(tablet) {
        display: block;
        color: $darkestGrey;
        font-size: 14px;
        width: 90%;
      }
    }
  }

  .menu-list__item__submenu {
    position: relative;
    @include for-size(tablet) {
      border-bottom: 1px solid $lightestGrey;
    }
    &.active {
      border-bottom: 4px solid var(--primary);
      @include for-size(tablet) {
        border-bottom: none;
        background-color: $lightestGrey;
      }
    }

    &:hover {
      border-bottom: 4px solid var(--primary);
      @include for-size(tablet) {
        border-bottom: 1px solid $lightestGrey;
      }
    }

    &.active:before,
    &:hover:before {
      content: ' ';
      position: absolute;
      background-color: var(--primary);
      top: 0;
      left: 0;
      width: 5px;
      height: 50px;
    }
  }


  .menu-list__item__submenu.open {
    height: auto;
  }

  .menu-list__item__submenu.open > .menu-list__submenu {
    display: block;
    background: $white;

    .menu-list__item {
      border: none;

      &__link {
        padding-left: 45px;
      }
    }

    .menu-list__item.active {
      background: none;
    }
  }

  .menu-list__item__submenu {
    cursor: pointer;
  }

  .circle {
    flex: 0 0 20px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 50%;
    font-size: 10px;
    color: #eeeeee;
    text-align: center;
    background: $primary;

    &-text {
      color: $white;
    }
  }


  .menu-list__item__submenu > .menu-list__item__link {

    .menu-list__item__label {
      padding-right: 10px;
    }

    &:after {
      font-family: icomoon;
      transition: all .3s ease;
      content: "\e904";
      color: $lightGrey;
      font-size: 16px;
    }
  }

  .menu-list__item__submenu.open > .menu-list__item__link {

    &:after {
      font-family: icomoon;
      transition: all .3s ease;
      content: "\e904";
      color: $lightGrey;
      transform: rotate(90deg);
    }
  }

  .menu-list__item__submenu {
    @include for-size(tablet) {
      &:after {
        display: none;
      }
    }
  }

  .menu-list__submenu {
    display: none;
  }
}

.menu-logout {
  background: $white;
  padding: 10px 0 20px 30px;
}
