form.full-height {
  //height: 100%;
}

.cke_combopanel__strinsert {
  width: 400px !important;
}

.form-line {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}

.pointer-none {
  pointer-events: none;
}

.civility-label {
  font-size: 11px !important; //overwrite default style
  top: -5px !important; //overwrite default style
  @include for-size(tablet) {
    font-size: 15px !important; //overwrite default style
    top: 0 !important; //overwrite default style
  }
}

.civility-label + .sub-wrapper,
.civility-label + .field-wrapper
{
  & > .field-wrapper__label {
    top: 10px;
  }
}

.submit-btn {
  @extend .btn-negative;
  margin: 20px 20px 20px 0;
}

.choices-padding {
  padding-top: 40px;
  @include for-size(tablet) {
    padding-top: 0;
  }
}

.delete-form-btn {
  position: absolute;
  left: 180px;
  bottom: 56px;
}

.loader-container.centered {
  height: 100%;
  padding-bottom: 60px;
}

.loader-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;

  .lds-dual-ring {
    display: inline-block;
    width: 64px;
    height: 64px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid var(--primary);
    border-color: var(--primary) transparent var(--primary) transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}