.operation {
  background-color: #FFF;
  display: flex;
  width: 100%;
  min-height: 80vh;

  .simulation__form {
    overflow-y: visible;
  }

  &__step-title {
    color: var(--primary);
    padding: 20px 0;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    max-width: 480px;
  }

  &__simple-text {
    font-size: 15px;
    line-height: 20px;
    color: var(--primary);
    width: 75%;
  }

  &__step-content {
    padding-bottom: 30px;
    width: 60%;
    color: var(--primary);

    .step-content__label {
      line-height: 25px;
    }

    .field-wrapper {
      width: 100%;
      display: block;
      min-width: 460px;
    }

    .field-wrapper.sub-wrapper {
      width: 100%;
    }
  }

  .field-wrapper.error.checkbox-wrapper .field-wrapper__label {
    display: inherit;
  }

  .field-wrapper error no-background checkbox-wrapper { // very specific case
    margin: 20px 0 10px;
  }

  &__synthesis {
    width: 100%;
    padding: 45px 45px 200px 45px;
    background: url(../../images/bg_form.png) 50% 100% no-repeat $lightestBlue;
    border-left: 1px solid $lightestGrey;

    &__title {
      color: var(--primary);
      padding: 0 0 45px 38px;
      font-weight: bold;
      font-size: 18px;
    }

    &__table-label {
      color: $lightGrey;
      text-transform: uppercase;
      width: 90%;
      margin: auto;
      padding: 20px 0;
      letter-spacing: .5px;
      font-weight: bold;
      font-size: 13px;
    }

    .simulation__form {
      position: relative;

      .btn-cta {
        position: absolute;
        top: 0;
        right: 60px;
      }
    }

    .upload-step {
      padding-left: 40px;

      &__message {
        margin: 5px 5px;
        color: red;
        font-size: 1em;
        line-height: 1.25em;

        ul {
          list-style: initial;
          margin-left: 20px;
          padding-left: 20px;
        }
      }
    }

    .pictos {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      @include for-size(desktop) {
        width: 150%;
        max-width: 719px;
      }

      &__container {
        width: 180px;
        text-align: center;
        margin: 30px auto 90px;
        cursor: pointer;
        height: 80px;
        border: 2px solid $primary;
        border-radius: 90px / 40px;
        padding: 5px;

        &.active {
          background: $primary;
        }

        .picto {
          margin-top: -30px;
        }

        .picto-label {
          font-weight: 100;
          display: inherit;
          padding-top: 10px;
          color: $primary;
        }
      }
    }

    .operation-table {
      width: 90%;
      margin: auto;
      padding: 20px 0;

      &__header {
        color: $lightGrey;
        display: flex;
        font-size: 11px;
        font-weight: bold;
        padding: 20px 0;

        &__item:first-child {
          width: 60%;
        }

        &__item:nth-child(2) {
          width: 15%;
        }

        &__item:nth-child(3) {
          width: 15%;
        }

        &__item:last-child {
          width: 10%;
        }
      }

      &__actions {
        color: $darkestGrey;
        width: 100%;

        &__list {
          display: flex;
          font-size: 13px;
          padding: 20px 0;
          border-bottom: 1px solid $lightestGrey;

          &__item:first-child {
            width: 60%;
            padding-right: 20px;
          }

          &__item:nth-child(2) {
            width: 15%;
          }

          &__item:nth-child(3) {
            width: 15%;
          }

          &__item:last-child {
            width: 10%;
          }
        }
      }
    }

    .btn-top {
      position: absolute;
      top: 240px;
      right: 30px;
    }
  }

  .operation-tab {
    display: none;
    min-width: 0;

    &.active {
      display: block !important;
    }
  }

}

.operation-list .table-body__detail .field-wrapper.inline-block {
  min-width: auto !important;
}

.operationCustomFields {
  .operationCustomLabel {
    position: relative !important;
    line-height: 30px !important;
    font-size: 13px !important;
    padding-top: 20px !important;
    padding: 0;
  }

  .field-wrapper__container {
    padding-top: 10px !important;
  }
}

.field-wrapper__radio-label {
  font-size: 13px !important;
  line-height: 19.5px;
}

.field-wrapper {
    .flatpickr-wrapper {
      display: block;
  }
}

.upload-step {
  .simulation__form__middleStep {
    padding: 0 80px;
  }

  .upload-list {
    width: 160%;
    min-width: 400px;

    @include for-size(desktop) {
      width: 190%;
    }
  }
}

.operation__synthesis .upload-step .upload-list {
  width: 100%;
}

.operation-list__content {
  min-height: 622px;

  .cards-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 50%;
    margin-top: 5px;

    .card {
      text-align: center;
      width: 50%;
      margin: 5px 5px;
      color: white;
      padding-top: 25px;
      padding-bottom: 25px;

      &__number {
        font-size: 1rem;
      }

      &__text {
        margin-top: 15px;
        font-weight: 100;
      }
    }

    .blue-card {
      background-color: var(--primary);
    }

    .green-card {
      background-color: var(--cta);
    }
  }
}

.operation-list__warning-message {
  background-color: $warning;
  color: $white;
  padding: 10px;

  .icon::before {
    color: inherit;
    margin-right: 15px;
  }
}

.operation-list__footer {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 5px;
  justify-items: center;
  align-items: center;

  .pages-navigation {
    grid-column-start: 2;
    display: inline-block;
  }

  .dropdown {
    justify-self: flex-end;

    .btn-dropdown {
      padding: 5px;
      background-color: transparent;
      border-radius: 3px;
      border: 1px solid var(--cta);
      color: var(--cta);
    }

    .dropdown-content.dropdown-up {
      top: -155px;
      right: 0px;
    }
  }
}

// operation header
.operation-list__header, .simulation-list__header, .user-list__header {
  margin-bottom: 10px;
  z-index: 2;
  .header-wrapper {
    @include for-size(tablet) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .buttons-wrapper {
      @include for-size(tablet) {
        display: flex;
        align-items: center;
      }


      .btn-cta {
        margin-left: 10px;
        height: 30px;
        padding: 0 8px;
        z-index: 2;
        @include for-size(tablet) {
          padding: 0 30px;
        }

        &__icon {
          margin-right: 0;
          @include for-size(tablet) {
            margin-right: 5px;
          }
        }

        &__label {
          display: none;
          @include for-size(tablet) {
            display: block;
          }
        }
      }

      .btn-negative {
        margin-left: 5px;
      }
    }
  }

  &__buttons {
    padding-top: 30px;

    .field-wrapper.inline-block {
      min-width: auto !important;
      margin: 0;
      padding: 0 15px 0 10px;
    }

    .btn-negative {
      margin-left : 10px;
    }

    .field-wrapper__checkbox-label {
      font-size: 14px;
    }

    .field-wrapper__checkbox:checked ~ .field-wrapper__checkbox-label--unchecked {
      display: none;
    }

    .field-wrapper__checkbox:not(:checked) ~ .field-wrapper__checkbox-label--checked {
      display: none;
    }
  }
}

// archived operations header

.archived-operations {
  .operation-list__header {
    display: flex;
    justify-content: space-between;

    .btn-cta {
      height: 30px;
      z-index: 2;
    }
  }
}


// bulk delete popin

.popin-warning {
  display: flex;
  padding-bottom: 20px;

  .icon {
    font-size: 20px;
  }

  &__message {
    text-align: left;
    font-size: 12px;
    padding-left: 10px;
    line-height: 15px;
  }
}

.popin-actions {
  padding-top: 20px;
  display: flex;
  align-items: center;

  .btn-multiple-confirmation {
    padding: 0 20px;
  }

  .link {
    padding-left: 10px;
  }
}

.popin-error-message {
  height: 20px;
  color: $danger;
  font-size: 11px;
  padding-top: 5px;
  font-weight: 100;
}

.strike {
  position: relative;
  display: inline-block;
}

.strike::before {
  content: '';
  border-bottom: 2px solid black;
  width: 100%;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-100%);
}


.cumulativeSimulation__wrapper {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
  width: 25%;
}

.popin__duplicate__subtitle {
  color: $primary;
  font-family: Sailec,sans-serif;
  font-size: 16px;
  line-height: 52px;
  font-weight: bold;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top:38px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $lightestGrey;
  }
}

.popin__duplicate__desc {
  color: $lightGrey;
  font-family: Sailec,sans-serif;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 15px;
}

#impersonate_operation {
  .popin__content {
    overflow-y: inherit;
  }

  .field-wrapper__select-options {
    z-index: 999;
  }

  .popin__footer {
    z-index: 0;
  }
}
