.errors-container {
  z-index: 3;
  font-size: 10px;
  padding-top: 5px;
  color: $danger;
  line-height: 13px;
  text-align: left;

  &__error {
    color: $danger;
  }

  &__warning {
    color: $warning;
  }
}

.checkbox-wrapper .errors-container {
  padding: 0px 0px 0px 12px;
}

.errors-container.no-label {
  position: absolute;
  padding: 5px 0 0 7px;

  &__error {
    color: $danger;
  }
}