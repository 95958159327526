.field-wrapper__checkbox {
  display: none;

  &-label {
    display: flex;
    cursor: pointer;
    position: relative;
    align-items: center;

    &.-noResult {
      color: $danger;
      
      &:before {
        display: none;
      }
    }

    &:before {
      content: "\e90c";
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      min-width: 20px;
      height: 20px;
      border: 1px solid $lightGrey;
      border-radius: $checkboxRadius;
      font-family: icomoon;
      color: $white;
      text-align: center;
      line-height: 20px;
      font-size: 12px;
      background-color: $white;
      //@extend %shadow-checkbox;
    }

    &.focus:before {
      border: 1px solid $cta;
    }
  }

  &:checked + &-label:before {
    border-color: var(--primary);
    background-color: var(--primary);
  }

}