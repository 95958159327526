.search-container {
  display: flex;
  padding: 10px 0;
  width: 100%;

  &__form {
    width: 100%;
    text-align: center;
    z-index: 2;
    @include for-size(tablet) {
      display: flex;
      flex-direction: row;
    }
  }

  &__field {
    display: flex;
    flex: 1;
    padding-left: 20px;
  }

  .btn-cta--big {
    margin-top: 10px;
    @include for-size(tablet) {
      margin: 0 0 0 10px;
      z-index: 2;
    }
  }
}
