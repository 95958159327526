.form.content {
  .icon-calendar {
    z-index : 1;
  }
}

#field_options_choices {
  .field-wrapper__label {
    padding-top: 2.5px;
    font-size: 10px;
    line-height: 30px;
  }
}

.generated-form-list {
  padding: 20px;
  text-align: left;

  .table {
    overflow-x: scroll;
  }
}