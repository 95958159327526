.select2-selection--single {
  height: 55px !important;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  font-weight: 100;

  .select2-selection__placeholder {
    font-weight: 100;
  }
}

.select2-selection--single .select2-selection__arrow {
  height: auto;
  position: relative !important;
  top: auto !important;
  right: 5px !important;
  display: flex;
  width: 20px;

  & > b {
    position: relative;
  }
}

a[class*="-collection-up"], a[class*="-collection-down"] {
  display: none;
}

a[class*="-collection-action"] {
  color: var(--primary);
}