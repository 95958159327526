fieldset {
  margin-bottom: 25px;

  legend {
    position: relative;
    display: block;
    margin-bottom: 25px;
    height: 32px;
    color: var(--primary);
    font-family: Sailec;
    font-weight: bold;
    font-size: 15px;
    border-bottom: 1px solid rgba(var(--primary), .05);

    &:after {
      content: ' ';
      position: absolute;
      display: block;
      bottom: 0;
      width: 80px;
      height: 5px;
      background-color: var(--primary);
    }
  }

}

