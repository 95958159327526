%shadow-default {
  box-shadow: 0 10px 20px rgba($black, .5);
}

%shadow-popin {
  box-shadow: 0 5px 10px rgba($black, .03);
}

%shadow-select {
  box-shadow: inset 0 1px 3px rgba($black, .25);
}

%shadow-checkbox {
  box-shadow: 0 2px 4px rgba($black, .5);
}

%shadow-menu {
  box-shadow: 10px 0 30px rgba($black, .1);
}

%shadow-login-block {
  box-shadow: 0 40px 80px rgba($black, .4);
}

%shadow-tooltip {
  box-shadow: 0 10px 30px rgba($black, .05);
}

%shadow-content {
  box-shadow: 0 5px 10px rgba($black, .3);
}

%shadow-sidebar {
  box-shadow: 0 1px 3px rgba($black, .3);
}