.pagination {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  .page-item {
    padding: 0 10px;
    .page-link {
      color: var(--primary);
      font-family: Sailec;
      font-size: 13px;
    }
  }
  .page-item.active {
    .page-link {
      font-weight: bold;
    }
    .sr-only {
      display: none;
    }
  }
}