.submenu {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: $white;
  @include for-size(tablet) {
    width: 280px;
    padding: 50px;
    border-right: 1px solid $lightestGrey;
  }

  &__title {
    display: none;
    @include for-size(tablet) {
      display: block;
      margin-bottom: 30px;
      text-align: left;
    }
  }

  &__list {
    overflow-y: scroll;

    @include for-size(tablet) {
      margin-left: -50px;
      margin-right: -50px;
    }
  }

  &__item {
    align-items: center;
    border-bottom: 1px solid rgba($black, .05);
    @include for-size(tablet) {
      border-bottom: 1px solid $lightestGrey;
      background-color: $bg;
    }
    &__link {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 80px;
      color: var(--primary);
      font-size: 15px;
      font-weight: 100;
      @include for-size(tablet) {
        padding: 0 25px;
      }

      &:after {
        margin: auto 0 auto auto;
        content: "\e904";
        color: var(--primary);
        font-family: icomoon;
        @include for-size(tablet) {
          right: 15px;
        }
      }
    }
    &__subtab {
      display: none;
      @include for-size(tablet) {
        position: absolute;
        top: 20px;
        left: 280px;
        overflow-y: scroll;
        height: 95%;
        margin: 1em 1em 0 1em;
        right: 0;
      }
    }
  }

  &__item__link.active  {
    color: $primary;
    @include for-size(tablet) {
      color: $white;
      font-weight: bold;
      background-color: var(--primary);
    }
    &:after {
      @include for-size(tablet) {
        color: $white;
      }
    }
  }

  &__item__link.active + &__item__subtab {
    display: block;
  }
}