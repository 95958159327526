.wrapper, .field-wrapper {
  position: relative;

  .icon-info, .icon-danger {
    position: absolute;
    right: -20px;
    top: -5px;
  }

  .icon-info:hover + .tooltip, .icon-danger:hover + .tooltip {
    display: block;
  }

  .icon-info:hover, .icon-danger:hover {
    cursor: pointer;
  }

  .tooltip {
    display: none;
    position: absolute;
    width: 400px;
    top: 20px;
    right: 0;
    color: $darkestGrey;
    z-index: 4;

    &-content {
      position: absolute;
      transform: translateX(calc(100% + 10px));
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
      right: 0;
      z-index: 2;
      background: $white;
      top: 0;
      border-radius: 3px;
      font-size: 10px;
      padding: 10px 10px;
      font-weight: 300;
      line-height: 17px;
    }

    &__list {
      font-size: 10px;
      font-weight: 300;
      line-height: 17px;
    }
  }

  .tooltip-left {
    .tooltip-content {
      transform: translateX(10px);
    }
  }
}

.operation .operation__step-content .wrapper { // specific for operation edition
  width: 390px;
}