.comments {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  height: 500px;
  overflow-y: scroll;
}

.comment {
  display: flex;
  flex-direction: column;
  border: 1px solid $lightestBlueGrey;
  border-radius: 3px;
  padding: 20px;
  margin-top: 20px;
  width: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      width: 20px;
      height: 20px;
      fill: $darkGrey;
    }
  }

  &__body {
    margin-top: 20px;
  }
}