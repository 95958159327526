.search-filters {
  align-items: center;
  justify-content: space-between;

  &__row {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;

    &:nth-of-type(2) {
      display: none;

      .active & {
        display: flex;
      }
    }
  }

  &__filters {
    position: relative;
    width: 100%;

    #filters {
      width: 100%;
    }

    #filters_depot {
      display: flex;
      width: 100%;
    }

    .filter-search--large {
      min-width: 250px;
    }

    .filter-search--no-border {
      border-right: none !important;
    }

    .filter {
      position: relative;
      display: flex;
      color: $lightGrey;
      font-weight: 100;
      padding-right: 10px;
      height: 30px;
      align-items: center;
      border-right: $lightGrey 1px solid;
      border-bottom: $lightestGrey 1px solid;
      flex: 1;
      min-width: 230px;

      .icon {
        position: absolute;
        right: 10px;
      }

      &__name {
        padding-right: 20px;
        font-size: 14px;
      }

      .flatpickr-input {
        padding-right: 25px;
        text-overflow: ellipsis;
      }

      &__input {
        font-weight: 100;
        color: $lightGrey;
        font-size: 14px;
        border: none !important; //overwrite default style
        padding-right: 25px;
        text-overflow: ellipsis;
        height: 30px;
      }

      &__input::placeholder {
        font-size: 12px;
      }

      &__counter {
        position: absolute;
        right: 25px;
        min-width: 18px;
        height: 18px;
        background: $primary;
        border-radius: 100%;
        color: white;
        font-size: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 3px;

        &-number {
          margin-top: 4px;
        }
      }
    }

    .state-filter__filter {
      width: 100%;
    }

    .state-filter__filter .column-filter__filter {
      .filter__input {
        width: 100px;
      }
    }

    .resultsFilter {
      display: none;
      position: absolute;
      padding: 0 10px;
      border-radius: 5px;
      border: $grey 1px solid;
      width: 300px;
      top: 50px;
      background: #fff;
      z-index: 2;

      .field-wrapper {
        display: initial !important;
        padding: 10px 0;
        font-size: 12px;
        color: $primary;
        margin: 12px;

        .icon {
          position: relative;
          font-size: 20px;
          color: inherit;
          padding-right: 10px;
          left: initial;
          top: initial;
        }
      }
    }
  }

  .filter-results {
    display: none;
    position: absolute;
    top: 50px;
    background: $white;
    padding: 0 10px;
    border-radius: 5px;
    border: $grey 1px solid;
    z-index: 1;
  }

  .search-filters__fields {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  .search-filters__options {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 20px;

    @include for-size(tabletLandscape) {
      flex-direction: row;
      justify-content: flex-start;
    }

    .filter__text,
    .search-filters__link {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .state-filter {
    &__dropdown {
      .field-wrapper__checkbox-label {
        color: $primary;
        white-space: nowrap;
        padding: 7px 10px 7px 0;
        font-size: 12px;
      }

      .icon {
        padding-right: 8px;
        font-size: 22px;
        position: initial;
      }

      .icon-success {
        color: $primary;
        font-size: 15px;
      }
    }

    &__filter {
      padding-right: 20px;
      font-size: 14px;
      position: relative;
    }

    &__filter::after {
      position: absolute;
      right: 2px;
      top: 12px;
      content: '\3008';
      display: inline-block;
      transform: rotate(-90deg);
      margin-bottom: -5px;
    }
  }

  .filters-displayed,
  .column-filter {
    position: relative;
    cursor: pointer;

    &__dropdown {
      .field-wrapper__checkbox-label {
        color: $primary;
        white-space: nowrap;
        padding: 7px 10px 7px 0;
        font-size: 12px;
      }

      .icon {
        padding-right: 8px;
        font-size: 22px;
        position: initial;
      }

      .icon-success {
        color: $primary;
        font-size: 15px;
      }
    }

    &__filter {
      font-size: 14px;
      position: relative;
    }

    &__filter::after {
      position: absolute;
      right: 0;
      top: 10px;
      content: '\3008';
      display: inline-block;
      transform: rotate(-90deg);
      margin-bottom: -5px;
      color: $primary;
    }

    .filter__text {
      padding-right: 20px;
      color: $primary;
      font-size: 10px;
      text-transform: uppercase;
      font-weight: 400;
    }
  }

  .column-filter {
    margin-left: auto;

    .filter-results {
      right: 20px;
    }
  }

  .filters-displayed {
    .filter-results {
      right: 20px;
      @include for-size(tabletLandscape) {
        right: auto;
      }
    }
  }

  .state-filter.list-filter .column-filter.list-filter {
    max-width: 130px;

    input {
      padding-right: 20px;
    }
  }

  .state-filter.open {
      .filter-results {
        display: block;
      }

    .state-filter__filter::after {
      transform: rotate(90deg);
      top: 2px;
      right: 20px;
    }
  }

  .filters-displayed.open,
  .column-filter.open {
    .filter-results {
      display: block;
    }

    .column-filter__filter::after {
      transform: rotate(90deg);
      top: 4px;
    }
  }

  .filter-date {
    position: relative;

    .rangeDate {
      border: none !important;

      .icon {
        position: absolute;
        right: 10px;
      }
    }

    .rangeDate::placeholder {
      font-size: 12px;
    }
  }

  &__link {
    cursor: pointer;
    color: $primary;
    font-size: 10px;
    text-transform: uppercase;
    display: flex;
    margin-left: 20px;

    .icon {
      padding-right: 5px;
    }

    &__label {
      white-space: nowrap;
    }
  }

  &__link_depot {
    align-items: center;
  }
}
