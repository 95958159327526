// Common.
$radius: 3px;
$checkboxRadius: 2.5px;

// Colors.
$transparent: transparent;

$white: #FFF;

$black: #000;

$primary: #1E6679;
$secondary: #BAD636;

$bg: #F6F6FB;

$cta: #4FC0D9;

$success: #8ED481;
$warning: #F5A623;
$danger: #FF5656;
$lightPrimary: rgba($primary, .2);

$darkBlueGrey: #4C5F64;
$lightestBlueGrey: #BFC2D2;
$lightestBlue: #F6FBFE;

$darkestGrey: #151515;
$darkGrey: #4A4A4A;
$grey: #636363;
$lightGrey: #9B9B9B;
$lightestGrey: #ECECEC;

// White mark default.
:root {
  --primary: #{$primary};
  --secondary: #{$secondary};
  --cta: #{$cta};
}
