.company-form {
  .region-collection {
    padding: 50px 0 0 20px;

    label {
      position: relative;
      height: 30px;
      display: flex;
      align-items: center;
      pointer-events: none;
    }
  }

  .field-wrapper__select-options {
    max-height: 115px;
  }
}

.datepicker-label {
  top: -9px;
  font-size: 10px !important; //overwrite field-wrapper_label style
  @include for-size(tablet) {
    top: 10px;
    left: 30px;
    font-size: 15px !important; //overwrite  style
  }
}