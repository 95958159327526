.searchbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 70%;
  padding-left: 12px;
  @include for-size(tablet) {
    box-shadow: 0 5px 10px rgba($black, 0.03);
    padding: 12px;
    background-color: $white;
    max-width: 700px;
    height: 50px;
    border-radius: $radius;
  }

  &__icon {
    color: $lightGrey;
    font-size: 20px;
  }

  &__field {
    width: 100%;
    border: none;
    color: $darkBlueGrey;
    font-family: Sailec;

    &::placeholder {
      color: $lightGrey;
    }
  }
}