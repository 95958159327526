@font-face {
  font-family: 'icomoon';
  src:  url('./fonts/icomoon.eot?fmhmvi');
  src:  url('./fonts/icomoon.eot?fmhmvi#iefix') format('embedded-opentype'),
    url('./fonts/icomoon.ttf?fmhmvi') format('truetype'),
    url('./fonts/icomoon.woff?fmhmvi') format('woff'),
    url('./fonts/icomoon.svg?fmhmvi#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add:before {
  content: "\e900";
}
.icon-danger:before {
  content: "\e901";
  color: $danger;
}
.icon-arrow-down:before {
  content: "\e902";
}
.icon-arrow-left:before {
  content: "\e903";
}
.icon-arrow-right:before {
  content: "\e904";
}
.icon-arrow-up:before {
  content: "\e905";
}
.icon-burger:before {
  content: "\e906";
}
.icon-calendar:before {
  content: "\e907";
}
.icon-close:before {
  content: "\e908";
}
.icon-duplicate:before {
  content: "\e909";
}
.icon-dashboard:before {
  content: "\e90a";
}
.icon-save:before {
  content: "\e924";
}
.icon-doc:before {
  content: "\e90b";
}
.icon-success:before {
  content: "\e90c";
}
.icon-dots:before {
  content: "\e90d";
}
.icon-download:before {
  content: "\e90e";
}
.icon-edit:before {
  content: "\e90f";
}
.icon-email:before {
  content: "\e910";
}
.icon-enter:before {
  content: "\e911";
}
.icon-file:before {
  content: "\e912";
}
.icon-filter:before {
  content: "\e913";
}
.icon-folder:before {
  content: "\e914";
}
.icon-home:before {
  content: "\e915";
}
.icon-info:before {
  content: "\e916";
}
.icon-loader:before {
  content: "\e917";
}
.icon-mov:before {
  content: "\e918";
}
.icon-pdf:before {
  content: "\e919";
}
.icon-ppt:before {
  content: "\e91a";
}
.icon-search:before {
  content: "\e91b";
}
.icon-settings:before {
  content: "\e91c";
}
.icon-simulator:before {
  content: "\e91d";
}
.icon-stats:before {
  content: "\e91e";
}
.icon-trash:before {
  content: "\e91f";
}
.icon-upload:before {
  content: "\e920";
}
.icon-user:before {
  content: "\e921";
}
.icon-users:before {
  content: "\e922";
}
.icon-warning:before {
  content: "\e923";
  color: $warning;
}
