.steps {
  display: block;
  padding: 15px 30px;
  @include for-size(tablet) {
    padding: 30px 0 0 40px;
  }

  &__list {
    margin-top: 35px;
    justify-content: space-around;
    padding: 0 20px;
    @include for-size(tablet) {
      margin-top: 10px;
      flex-direction: column;
      padding: 0;
    }

    &__item {
      display: inline-flex;
      position: relative;
      padding: 0 10px;
      flex-direction: column;
      height: 100%;
      border-top: 1px solid rgba($black, .1);
      @include for-size(tablet) {
        padding: 0 0 12px 14px;
        border-top: 0px;
        border-left: 1px solid rgba($black, .1);
      }

      &:last-child {
        border-left: 0;
      }

      &:before {
        box-sizing: border-box;
        position: absolute;
        content: ' ';
        margin-top: -7px;
        margin-left: 10px;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        background-color: $lightGrey;
        border: 2px solid $white;
        @include for-size(tablet) {
          margin-top: -2px;
          margin-left: -20px;
        }
      }

      .progress{
        display: block;
        position: absolute;
        left: -1px;
        top: 0;
        background: var(--primary);
        width: 1px;
        transition: all .3s ease-in-out;
      }

      &-step {
        padding-top: 20px;
        display: flex;
        margin-bottom: 5px;
        font-size: 10px;
        color: $lightGrey;
        letter-spacing: 2px;
        text-transform: uppercase;
        font-family: "DIN Condensed";
        white-space: nowrap;
        @include for-size(tablet) {
          padding-top: 0;
        }
      }

      &-title {
        display: none;
        @extend .title-h4;
        @include for-size(tablet) {
          display: flex;
          line-height: 20px;
          color: $lightGrey;
        }

        &:hover {
          cursor: pointer;
        }
      }

      &.active {

        &:before {
          background-color: var(--primary);
        }

        .progress{
          min-height: 15%;
        }
      }

      &.done {
        &:before {
          background-color: var(--primary);
        }
        .progress{
          height: 100%;
        }
      }

      &.done &-step,
      &.done &-title
      {
        color: var(--primary);
      }

      &.active &-step,
      &.active &-title {
        color: var(--primary);
      }
    }
  }
}