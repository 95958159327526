.input-file {

  &:focus + label {
    cursor: pointer;
  }
}

.field-wrapper.focus .field-wrapper__label__file {
  border-radius: 4px;
  border: 2px solid #4FC0D9;
  box-shadow: 0px 0px 4px rgba(79, 192, 217, 0.2);
}