.form-sheet {
  @extend %shadow-content;
  display: flex;
  overflow: hidden;
  padding: 0 20px;
  width: 100%;
  position: relative;
  background: $white;
  @include for-size(tablet) {
    padding: 0;
    height: 80vh;
    background: none;
  }
}

.ckeditor_tab_container {
  width: 95%;
  margin: auto;
}

.popin .field-wrapper {
  .icon-info {
    left: 50%;
  }

  .tooltip {
    left: -40px;
  }
}
