/*!
 *   Created by PhpStorm.
 *   User: alexislacroix
 *   Date:  7/9/2021
 *   Time: 10:10
 */

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: #f6fbfe;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;

  &:after {
    content: '\02795'; /* Unicode character for "plus" sign (+) */
    font-size: 13px;
    color: #777;
    float: right;
    margin-left: 5px;
  }

  &.active:after {
    content: "\2796"; /* Unicode character for "minus" sign (-) */
  }

  /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
  &.active, &.accordion:hover {
    background-color: #ccc;
  }
}

/* Style the accordion panel. Note: hidden by default */
.panel {
  padding: 0 18px;
  background-color: white;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.2s ease-out;

  &.active {
    max-height: 3000px;
    overflow: visible;
  }

  .panel-content {
    width: 60%;
  }
}