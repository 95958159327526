@keyframes notification-open {
  0% { top: -55px }
  25% { top: 0 }
  75% { top: 0 }
  100% { top: -55px }
}

@keyframes long-notification-open {
  0% { top: -55px }
  13% { top: 0 }
  87% { top: 0 }
  100% { top: -55px }
}

@keyframes popin-open {
  from { opacity: 0 }
  to { opacity: 1 }
}

@keyframes popin-close {
  from { opacity: 1 }
  to { opacity: 0 }
}