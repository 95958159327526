.textarea {
  width: 100%;
  height: 150px;
  font-size: 15px;
  border-radius: 3px;
  resize: none;
  margin: 20px auto;
  font-weight: 100;
  padding: 10px;
  color: $grey;
  border-color: $lightestGrey;
}