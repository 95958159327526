.btn {
  display: inline-flex;
  align-items: center;
  padding: 0 30px;
  height: 30px;
  color: var(--primary);
  border: 1px solid var(--primary);
  border-radius: $radius;
  background-color: $transparent;
  white-space: nowrap;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 10px;
  outline: none;

  &-negative {
    @extend .btn;
    color: $white;
    background-color: var(--primary);

    & + & {
      margin-left: 5px;
    }
  }

  &-danger {
    @extend .btn;
    color: $white;
    background-color: $danger;
    border-color: $danger;
    box-shadow: 0px 5px 20px rgba($danger, .4);
  }

  &-cta {
    @extend .btn;
    @extend .btn-negative;
    height: 40px;
    background-color: var(--cta);
    border-color: var(--cta);
    box-shadow: 0px 5px 20px rgba(var(--cta), .4);

    &__icon {
      display: inline-flex;
      margin-right: 5px;
      font-size: 12px;
      line-height: 40px;
      font-weight: bold;
    }

    &--big {
      @extend .btn-cta;
      height: 55px;
    }

    &--big &__icon {
      line-height: 55px;
    }

    &--disabled {
      pointer-events: none;
      cursor: not-allowed;
      background-color: rgb(211, 211, 211);
      border-color: rgb(211, 211, 211);
      box-shadow: 0px 5px 20px rgba(211, 211, 211, .4);
    }
  }

}

.xs-btn-negative {
  @extend .btn-negative;
  .btn-label {
    display: none;
    @include for-size(desktop) {
      display: initial;
    }
  }

}

.xs-btn-negative:before {
  content:'+';
  @include for-size(desktop) {
    content: '';
  }
}

.dropdown {
  position: relative;
  display: inline-block;

  &-content {
    margin: 5px 0 5px 0;
    display: none;
    position: absolute;
    background-color: $bg;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    border: 1px solid $lightestBlueGrey;
    z-index: 1;
    right: 2px;

    &.dropdown-up {
      right: 2px;
      top: -185px;
    }

    &.open {
      display: block;
    }

    & > a {
      display: block;
      padding: 10px;
      color: $darkBlueGrey;
      font-family: Sailec;
      font-weight: 100;
      font-size: 15px;
      &:hover {
        background: $lightestGrey;
      }
    }
  }

  &-top {
    top: -250px;
  }
}

.btn:disabled, .btn-negative:disabled {
  background-color: #eee;
  color: var(--primary);
}
