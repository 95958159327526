.advisor {
  background-color: var(--primary);
  width: 35px;
  height: 35px;
  border-radius: 50%;
  @include for-size(tablet) {
    display: flex;
    flex-direction: column;
    width: 280px;
    height: 50px;
    background-color: $white;
    border-radius: $radius;
    margin-left: 50px;
  }
  @extend %shadow-popin;

  &__title {
    display: none;
    @include for-size(tablet) {
      display: flex;
      align-items: center;
      padding: 0 10px;
      height: 15px;
      color: $white;
      background-color: var(--primary);
      text-transform: uppercase;
      font-size: 8px;
      letter-spacing: 1px;
      font-family: Sailec;
      border-top-left-radius: $radius;
      border-top-right-radius: $radius;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }

  &__name {
    display: none;
    color: $white;
    @include for-size(tablet) {
      display: block;
      color: var(--primary);
      font-weight: bold;
    }
  }
  .link {
    display: none;
    @include for-size(tablet) {
      display: flex;
      align-items: center;
    }
  }
}