.step-title {
  padding: 20px 0;
  display: flex;
  align-items: baseline;
  &__span {
    font-size: 19px;
    font-weight: 100;
  }
  &__label {
    padding-left: 24px;
    line-height: 28px;
  }
}