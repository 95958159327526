.dashboard {
  width: 100%;

  .btn-cta {
    z-index: 2;
    @media only screen and (min-width: 1250px) {
      padding: 0 10px;
    }

    .icon {
      margin-right: 0;
      @media only screen and (min-width: 1250px) {
        margin-right: 5px;
      }
    }


    span {
      display: none;
      @media only screen and (min-width: 1250px) {
        display: initial;
      }
    }
  }

  .btn-negative {
    padding: 0 13px;
    @media only screen and (min-width: 1350px) {
      padding: 0 30px;
    }
  }

  .table {
    tr {
      td:first-child {
        width: 150px;
      }

      td:nth-child(2) {
        width: 100px;
      }

      td:nth-child(3) {
        width: 100%;
        text-align: right;
      }
    }

    .shorted-txt {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100px;
      @include for-size(tablet) {
        max-width: 150px;
      }
    }
  }

  &__row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    @include for-size(tablet) {
      flex-direction: row;
    }

    .field-wrapper {
      min-width: 190px;
    }

    .content {
      display: flex;
      margin-bottom: 28px;
      width: 100%;
      border-radius: $radius;
      @include for-size(tablet) {
        width: calc(50% - 7px);
        display: block;
        height: 330px;
      }

      .table {
        display: block;
        overflow: initial;

        &__container {
          display: block;

          .table-body {
            display: block;
            width: 100%;
            height: 160px;
            overflow-y: scroll;
            overflow-x: hidden;
            @include for-size(tablet) {
              height: 200px;
            }
          }
        }
      }

      .cards-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        height: 50%;
        margin-top: 50px;

        .card {
          text-align: center;
          width: 50%;
          margin: 5px 5px;
          color: white;
          padding-top: 25px;

          &__number {
            font-size: 1.5rem;
          }

          &__text {
            margin-top: 15px;
            font-weight: 100;
          }
        }

        .blue-card {
          background-color: $primary;
        }

        .green-card {
          background-color: $cta;
        }
      }

      .btn-cta--big {
        height: 40px;
        padding: 0 13px;
        @include for-size(tablet) {
          padding: 0 30px;
        }

        .icon {
          font-size: 15px;
          @include for-size(tablet) {
            font-size: 11px;
          }
        }

        span {
          display: none;
          @include for-size(tablet) {
            display: block;
          }
        }
      }

      &__body {
        display: block;
        overflow-y: scroll;
        height: 225px;

        &__placeholder {
          text-align: center;
          padding: 30px 0;
        }

        &__message {
          text-align: center;
          color: $lightGrey;
          font-weight: 100;
        }
      }

      &__body.noscroll {
        overflow-y: initial;
      }

      &__body.documents {
        justify-content: space-between;

        .list {
          width: 100%;
          margin: 0 0;
          overflow-y: initial;
        }

        .icon {
          min-width: 22px;
        }
      }

      .icon-calendar {
        z-index: 2;
      }

      .icon-calendar + .flatpickr-wrapper > input {
        font-size: 12px;
      }

      .flatpickr-calendar.static {
        right: 0px;
      }

    }

    .content__statistics {
      .content__header {
        flex-direction: column;
        @include for-size(desktop) {
          flex-direction: row;
        }
        .form {
          width: 100%;
          @include for-size(desktop) {
            width: 50%;
          }
        }
      }
    }
  }
}

.actions-list {
  display: flex;
  padding: 15px 0;

  &__icon-container {
    width: 35px;
    height: 35px;
    background: $warning;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      padding-bottom: 3px;
    }

    .icon-danger:before {
      color: #FFF !important;
      font-size: 22px;
    }
  }

  &__details {
    padding-left: 10px;

    &__date {
      font-size: 11px;
      padding-bottom: 3px;
      color: $darkestGrey;
    }

    &__info, &__link {
      font-size: 13px;
      line-height: 25px;
    }

    .upload-link {
      text-decoration: underline;
      color: $darkestGrey;
    }
  }

}