.login-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $bg;
  position: relative;

  &__logo {
    position: absolute;
    top: 35px;
    left: 5%;
  }

  &__img {
    width: 80%;
    max-width: none;
    @include for-size(tablet){
      width: 100%;
      max-width: 180px;
    }
  }
}