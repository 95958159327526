.submenu__item__subtab {
  text-align: center;
  .title-h2 {
    display: none;
    @include for-size(tablet) {
      display: block;
      text-align: left;
      font-weight: 100;
      padding: 50px 0 0 100px;
    }
  }
  .title-h3 {
    font-weight: 100;
    font-size: 15px;
    line-height: 22px;
    padding-bottom: 15px;
    text-align: left;
    @include for-size(tablet) {
      padding: 10px 0 10px 100px;
    }
  }

  .form-container {
    @include for-size(tablet) {
      width: 100%;
      display: flex;
      padding: 20px 0 0 100px;
    }
    &__col-right {
      @include for-size(tablet) {
        width: 50%;
      }

      .annotation {
        color: $primary;
        padding-top: 15px;
        font-size: 12px;
        white-space: nowrap;
        font-weight: 100;
      }
    }
    input {
      @include for-size(tablet) {
        background-color: $white;
      }
    }
  }

  form[name="document"] {
    .vich-file {
      @include for-size(tablet) {
        padding-left: 100px;
      }
    }

    button[type="submit"] {
      @extend .btn-negative;
      margin: 10px auto;
    }
  }
}

.select2-search__field {
  padding-left: 7px !important; // overwrite default style
}