.popin-open {
  overflow: hidden;
}

.popin {
  display: none;
  position: relative;
  margin: auto;
  padding: 50px;
  width: 800px;
  max-height: 80vh;
  background-color: $white;
  border-radius: $radius;
  flex-direction: column;
  z-index: 0;

  &__content {
    overflow-y: scroll;
  }

  @extend %shadow-popin;

  &__close-container {
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 10px;
    cursor: pointer;

    .icon {
      font-size: 12px;
      color: var(--primary);
    }
  }

  &__actions {
    display: flex;
    align-items: center;

    .link {
      padding-left: 20px;

      .icon {
        transform: rotate(180deg);
        font-size: 11px;
      }

      &__label {
        margin-left: 5px;
      }
    }
  }

  &__surtitle {
    color: var(--primary);
    font-family: DIN Condensed;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  &__title-container {
    padding-bottom: 10px;

    .popin__title {
      color: var(--primary);
      font-family: Sailec;
      font-size: 25px;
      line-height: 35px;
      font-weight: bold;
    }
  }


  &__subtitle {
    color: $lightGrey;
    font-family: Sailec;
    font-size: 15px;
    line-height: 25px;
    font-weight: 100;
  }

  &--large {
    width: 80%;
  }

  &-background {
    @extend %background-popin;
    display: none;

    &.open {
      display: flex;

      .popin {
        display: flex;
      }
    }
  }

  &-field {
    overflow-y: hidden;
    position: fixed;
    padding: 25px;

    .popin {
      max-height: 100%;
      top: 0px;

      .popin__content {
        overflow: scroll;
      }

      .wrapper {
        text-align: left;
        margin-right: 10px;
      }
    }
  }

  .popin__footer {
    padding: 40px 0 20px;
    bottom: 0;
    height: 60px;
    background: #FFF;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}

.upload-step {
  .popin__title {
    font-size: 20px;
  }

  .popin-section {
    .dropzone {
      border-radius: $radius;
      border: 1.5px dashed $lightGrey;
      background: rgba($lightestGrey, 0.3);
    }
  }
}

// requestProof popin

#state_requestProof {

  .field-wrapper {
    margin: 0;
  }

  .field-wrapper__label {
    height: 30px;
    padding: 0;
  }

  label:not(.field-wrapper__label) {
    display: none;
  }
}

.prime-delete-popin .popin__content {
  overflow-y: inherit;
}