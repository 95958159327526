.title {

  &-h1 {
    font-size: 25px;
    color: var(--primary);
    font-family: "DIN Condensed";
    font-weight: 100;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 5px 0 10px 0;

    &__subtitle {
      font-size: 14px;
      color: #4A4A4A;
    }
  }

  &-h2 {
    color: var(--primary);
    font-family: Sailec;
    font-size: 25px;
    line-height: 35px;
    font-weight: bold;
  }

  &-h3 {
    color: var(--primary);
    font-family: Sailec;
    font-weight: bold;
    font-size: 20px;
  }

  &-h4 {
    color: var(--primary);
    font-family: Sailec;
    font-weight: bold;
    font-size: 15px;
  }

}
