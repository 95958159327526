.alert {
  display: flex;
  position: relative;
  margin: auto;
  padding: 30px;
  width: 386px;
  height: auto;
  background-color: $white;
  border-radius: $radius;
  flex-direction: column;
  z-index: 10;

  @extend %shadow-popin;

  &__title {
    padding: 10px 0;
    font-weight: bold;
    color: var(--primary);
    font-size: 17px;
    line-height: 24px;
    text-align: center;
  }

  &__content {
    padding: 10px 0;
    color: $darkGrey;
    font-size: 14px;
    text-align: center;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
  }

  &-background {
    @extend %background-popin;
  }
}
