.summary {
  background: white;
  width: 75%;
  margin: 0 auto;
  height: 472px;
  margin-bottom: 50px;
  position: relative;
  &__content {
    padding: 40px 30px;

    &__title {
    font-family: DIN Condensed;
    color: var(--secondary);
    text-transform: uppercase;
    font-size: 20px;
    letter-spacing: 2px;
  }

  &__operation-name {
    padding: 10px 0;
    color: var(--primary);
    font-size: 25px;
    font-weight: bold;
  }

  &__sheet-link {
    padding: 10px 0;
    color: $primary;
    font-size: 15px;
    font-weight: 100;
  }
}

&__container {
  position: absolute;
  top: 200px;
  background: var(--primary);
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 30px 25px;
  color: $white;

  &__amount, &__volume {
    width: 50%;

    .amount__title, .volume__title {
      font-family: DIN Condensed;
      color: var(--secondary);
      text-transform: uppercase;
      padding-bottom: 12px;
      letter-spacing: 2px;
    }

      .amount__amount, .volume__amount {
        font-size: 25px;

        .amount__previousAmount {
          font-size: 12px;
          padding-left: 5px;
          font-weight: 100;
        }
      }
    }

    &__calculation, .bonus-span {
      width: 100%;
      font-size: 11px;
      padding: 7px 0;
      opacity: .6;
      font-weight: 100;
    }
    &__bonus {
      width: 100%;
      font-size: 11px;
    }
    &__modifier {
      width: 100%;
      color: $success;
      text-transform: uppercase;
      padding-top: 25px;
      font-size: 12px;
      font-weight: 100;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__info {
    display: flex;
    position: absolute;
    bottom: 70px;
    width: 90%;
    left: 20px;
    color: grey;
    font-size: 11px;
    align-items: center;
    font-weight: 100;

    .icon {
      padding-right: 7px;
      font-size: 15px;
    }

    &__message {
      padding-top: 3px;
      line-height: 12px;
    }
  }

  .btn, .btn-negative {
    position: absolute;
    bottom: 20px;
    display: flex;
    justify-content: center;
  }

  .btn {
    right: 230px;
    max-width: 220px;
  }

  .btn-negative {
    right: 20px;
    width: 200px;
    font-weight: 100;
  }
}