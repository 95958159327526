.login-block {
  @extend %shadow-login-block;
  background-color: $white;
  width: 90%;
  @include for-size(tablet) {
    display: flex;
    flex-direction: row;
    width: 600px;

    @media all and (-ms-high-contrast:none)
    {
      display: table;
    }
  }

  &__title-container {
    background-color: var(--primary);
    position: relative;
    width: 100%;
    @include for-size(tablet) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 25px 25px;
      width: 215px;

      @media all and (-ms-high-contrast:none)
      {
        display: table-cell;
      }
    }

    &:before {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 10px;
      background-color: var(--secondary);
    }
  }

  &__title {
    @extend .title-h1;
    padding: 30px 20px 20px 20px;
    line-height: 35px;
    color: $white;
    font-size: 23px;
    @include for-size(tablet){
      font-size: 30px;
      display: flex;
      align-items: center;

      @media all and (-ms-high-contrast:none)
      {
        display: block;
      }
    }
  }

  &__content {
    position: relative;
    padding: 15px 25px;
    @include for-size(tablet) {
      width: 385px;

      @media all and (-ms-high-contrast:none)
      {
        display: table-cell;
      }
    }

    .field-wrapper {
      width: 100%;
      margin: 0;
      min-width: auto;
    }
  }

  &__form {
    @media all and (-ms-high-contrast:none)
    {
      display: block;
    }

    .field-wrapper {
      display: block;
      height: auto;
      padding: 10px 0;
      @include for-size(tablet) {
        padding: 0 0 10px 0;
      }
      &__input {
        width: 100%;
        margin-top: 10px;
        height: 45px;
      }
    }
  }

  &__field {
    padding: 0;
    width: 100%;
    height: 35px;
    border: none;
    border-bottom: 1px solid $lightGrey;
    border-radius: 0;
  }

  &__label {
    color: $lightGrey;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 20px 0 10px;
    font-size: 11px;
    @include for-size(tablet){
      font-size: 12px;
      padding: 0;
    }
  }

  &__submit {
    align-self: flex-start;
    margin: 25px auto 35px;
    width: 70%;
    padding: 13px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;

    @include for-size(tablet) {
      margin: 0;
      width: 150px;
      padding: 8px;
      position: absolute;
      bottom: 15px;
      left: 25px;
    }

    @include for-size(tablet) {

    }

    @media all and (-ms-high-contrast:none)
    {
      display: block;
    }
  }

  .reset-btn {
    position: relative;
    bottom: initial;
    left: initial;
  }

  &__subtitle {
    font-size: 14px;
    padding-bottom: 10px;
    @include for-size(tablet) {
      font-size: 17px;
    }
  }

  &__description {
    font-size: 14px;
    color: $lightGrey;
  }

  &__success {
    line-height: 22px;
  }

  &__psswd-link {
    text-decoration: underline $lightGrey;
    @include for-size(tablet) {
      position: absolute;
      bottom: 15px;
      right: 25px;
    }

    .link__label {
      font-size: 11px;
      color: $lightGrey;
    }
  }

  &__register-link {
    text-decoration: underline $lightGrey;
    @include for-size(tablet) {
      position: absolute;
      bottom: 30px;
      right: 25px;
    }

    .link__label {
      font-size: 11px;
      color: $lightGrey;
    }
  }

  &__back-link {
    padding-bottom: 15px;
    justify-content: left;
    text-transform: uppercase;
    display: block;
  }
  &__error-message {
    color: $danger;
    font-size: 12px;
    padding: 5px 0;
    margin-bottom: 34px;
  }
}
