@mixin for-size($range) {
  $tablet: $bp-tablet;
  $tabletLandscape: $bp-tablet-landscape;
  $desktop: $bp-desktop;

  @if $range == phone-only {
    @media (max-width: #{$tablet - 1}) { @content; }
  } @else if $range == tablet {
    @media (min-width: $tablet) { @content; }
  } @else if $range == tabletLandscape {
    @media (min-width: $tabletLandscape) { @content; }
  } @else if $range == desktop {
    @media (min-width: $desktop) { @content; }
  }
}

// Breakpoint
$bp-tablet: 600px;
$bp-tablet-landscape: 1024px;
$bp-desktop: 1200px;
$bp-desktop-xl: 2000px;