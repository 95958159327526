.white-mark {
  border-radius: $radius;
  .icon {
    padding-right: 8px;
  }

  .btn-cta--big {
    padding: 0 15px;
    margin-left: 10px;
    @include for-size(tablet) {
      padding: 0 30px;
      margin-left: 0;
    }
  }
}