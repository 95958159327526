.action-bar {
  position: fixed;
  bottom: 0;
  height: 60px;
  background: $white;
  left: 235px;
  right: -5px;
  z-index: 4;
  padding: 0 20px 0 30px;
  border-top: solid 1px $lightestGrey;
  border-left: solid 1px $lightestGrey;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__right-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .btn-negative {
      margin-left: 10px;
    }
  }
}

.multiple-actions-bar {
  @extend .action-bar;

  .btn-negative {
    padding: 0 15px;
  }
}