.notification {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  padding: 10px;
  width: 100%;
  height: 52px;
  background-color: $success;
  z-index: 9999;
  @include for-size(tablet) {
    margin-left: 235px;
    height: 38px;
  }

  &.open {
    animation-name: notification-open;
    animation-duration: 4s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }

  &.notification-warning.open {
    animation-name: long-notification-open;
    animation-duration: 10s;
  }

  &-warning {
    background-color: $warning;
  }

  &-danger {
    background-color: $danger;
  }

  &__icon,
  &__close {
    color: $white;

    &.icon-alert,
    &.icon-wait {
      font-size: 25px;
    }
  }

  &__icon {
    margin-right: 15px;
  }

  &__message {
    flex: 1;
    font-size: 13px;
    color: $white;
  }

}

